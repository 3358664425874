import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, BrowserRouter } from "react-router-dom";

import Recuperar from "../components/auth/Recuperar";

import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import { AppProveedores } from "./../components/main/AppProveedores";
import Login from "../components/auth/Login";
import { refreshLogin } from "../store/slices/auth/thunk";
import Loader from "../components/ui/Loader";
import ValidateHash from "../components/auth/ValidateHash";

const AppRoute = () => {
  const dispatch = useDispatch();
  const { idUsuario } = useSelector((state) => state.auth.credential);
  const { isLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(refreshLogin());
  }, [dispatch]);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoutes idUsuario={idUsuario} />}>
            <Route exac path='/app/*' element={<AppProveedores />} />
            <Route path='*' element={<AppProveedores />} />
          </Route>

          <Route element={<PublicRoutes idUsuario={idUsuario} />}>
            <Route path='/auth/login' element={isLoading ? <Login /> : <Loader />} />
            <Route path='/auth/recuperar' element={<Recuperar />} />
            <Route path='/app/proveedor/:temp' element={<ValidateHash />} />
            <Route path='/app/usuarios/:temp' element={<ValidateHash />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AppRoute;
