import { Table, Radio, Tag, Empty, Modal, Select, Button, Form, Popover } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHelpToken } from "../../helper/fetch";
import { Image } from "antd";
import PdfViewer from "./PdfViewer ";
import { EyeFilled, CheckCircleOutlined, CloseCircleOutlined, ClockCircleOutlined, MinusCircleOutlined, EllipsisOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { reloadCount } from "../../store/slices/productos/thunk";
import CompletarForm from "./CompletarForm";
import TabsPage from "./TabsPage";
import moment from "moment";
import { setabKey } from "../../store/slices/ui/uiModal";

import { useTableShearch } from "../../hooks/hooksproveedores/useTableShearch";
import Card from "antd/lib/card/Card";
let opciones = [
  {
    value: 1,
    label: "Pendientes",
  },
  {
    value: 2,
    label: "Con errores",
  },
  {
    value: 3,
    label: "Rechazados",
  },
  {
    value: 4,
    label: "Aprobados",
  },
  {
    value: 5,
    label: "Todos",
  },
];

const Proveedor = () => {
  const dispatch = useDispatch();
  const [url, setUrl] = useState("");
  const [productos, setProductos] = useState([]);
  const [estados, setEstados] = useState([]);
  const { reload } = useSelector((state) => state.storeReload);
  const { credential } = useSelector((state) => state.auth);
  const { fkIdPerfil, idUsuario, cod_luc } = credential;
  const [showPdf, setShowPdf] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [aprobado, setAprobado] = useState({});
  const [row, setRow] = useState([]);
  const [update, setUpdate] = useState(false);
  const { getColumnSearchProps } = useTableShearch();

  const handleList = async (e) => {
    await getproductos(e);
  };

  const showModal2 = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel2 = () => {
    setIsModalOpen(false);
  };

  const showModal3 = () => {
    setIsModalOpen2(true);
  };

  const handleOk2 = () => {
    setIsModalOpen2(false);
  };

  const handleCancel3 = () => {
    setIsModalOpen2(false);
    dispatch(setabKey(1));
  };

  const showModal = (llave_pdf) => {
    setShowPdf(true);
    setUrl(llave_pdf);
  };

  const handleCancel = () => {
    setShowPdf(false);
  };

  const getproductos = async (tipo) => {
    let datos = [];
    const res = await fetchHelpToken("productos", { fkIdPerfil, idUsuario, cod_luc, tipo }, "POST");
    const data = await res.json();
    if (data.productos.length > 0) {
      data.productos.map(({ ...el }) => {
        el.key = el.cod_producto;
        return datos.push(el);
      });

      setProductos(datos);
    } else {
      setProductos(datos);
    }
  };

  const getEstados = async () => {
    const res = await fetchHelpToken("productos/estados");
    const data = await res.json();
    if (data.ok) {
      setEstados(data.estados);
    }
  };

  useEffect(() => {
    // getproductos();
    getEstados();
  }, [update]);

  const handleRadio = (e, record) => {
    const { value } = e.target;
    const { cod_producto } = record;
    let msg = "";

    switch (value) {
      case 1:
        msg = "poner en pendiente";
        break;
      case 2:
        msg = "poner con errores";
        break;
      case 3:
        msg = "rechazar";
        break;
      case 4:
        msg = "aprobar";
        break;
      default:
        return value;
    }
    //para quitar aprobacion

    if (fkIdPerfil === 6) {
      Swal.fire("Comunicado", "Opción no disponible.!", "warning");
      return;
    }

    Swal.fire({
      title: `Está seguro de ${msg} el producto ${record.nombres}?`,
      html: `<textarea id="swal-input1" class="swal2-textarea" placeholder="Ingresa el mensaje a enviar al proveedor" style="width:80%"></textarea> `,
      preConfirm: () => {
        return document.getElementById("swal-input1").value;
      },
      showDenyButton: true,
      confirmButtonText: "Si",
      denyButtonText: "No",
      confirmButtonColor: "#6a2831",
      denyButtonColor: "#b60000",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (value === 4) {
          record.value = value;
          record.sms = result.value;
          record.cod_luc = cod_luc;
          setAprobado(record);
          showModal2();
        } else {
          const res = await fetchHelpToken(`productos/cambiar/`, { cod_producto, value, sms: result.value }, "POST");
          const data = await res.json();

          if (data.ok) {
            Swal.fire("Cambio realizado!", "", "success");
            const newProductos = productos.map((el) => {
              if (el.cod_producto === cod_producto) {
                el.cod_estado = parseInt(value);
              }
              return el;
            });

            setProductos(newProductos);
            dispatch(reloadCount(!reload));
          } else if (result.isDenied) {
            Swal.fire("No se realizó ningun cambio", "", "info");
          }
        }
      }
    });
  };

  const columns = [
    {
      title: "Producto",
      dataIndex: "nombres",
      key: "nombres",
      width: "270px",
      ...getColumnSearchProps("nombres"),
      render: (text) => (
        <div
          style={{
            maxWidth: "270px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          title={text}
        >
          <span style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }} id='nombre'>
            {text}
          </span>
        </div>
      ),
      // render: (_, record) => {
      //   return <span style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }} id="nombre" >{record.nombres}</span>;
      // },
    },
    {
      title: "Laboratorio",
      dataIndex: "laboratorio_fabricante",
      key: "laboratorio_fabricante",
      width: "260px",
      ...getColumnSearchProps("laboratorio_fabricante"),
      render: (text) => (
        <div
          style={{
            maxWidth: "260px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          title={text}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Pais",
      dataIndex: "pais",
      key: "pais",
      width: "120px",
    },
    {
      title: "Tipo de producto",
      dataIndex: "tipo_producto",
      key: "tipo_producto",
      ellipsis: true,
      width: "150px",
    },
    {
      title: "Envase Primario",
      dataIndex: "es_envase_primario",
      key: "es_envase_primario",
      render: (_, { es_envase_primario }) => (es_envase_primario ? <p>Si</p> : <p>No</p>),
      ellipsis: true,
      width: "140px",
    },
    {
      title: "Envase Secundario",
      dataIndex: "es_envase_secundario",
      key: "es_envase_secundario",
      render: (_, { es_envase_secundario }) => (es_envase_secundario ? <p>Si</p> : <p>No</p>),
      ellipsis: true,
      width: "150px",
    },
    {
      title: "Temperatura Máxima",
      dataIndex: "temperatura_maxima",
      key: "temperatura_maxima",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Calificación Arcsa",
      dataIndex: "calificacion",
      key: "calificacion",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Clasificación del medicamento",
      dataIndex: "clasificicacion_medicamento",
      key: "clasificicacion_medicamento",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Principio activo",
      dataIndex: "principio_activo",
      key: "principio_activo",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Clasificación Act 1",
      dataIndex: "clasificacion_atc_1",
      key: "clasificacion_atc_1",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Clasificación Act 2",
      dataIndex: "clasificacion_atc_2",
      key: "clasificacion_atc_2",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Clasificación Act 3",
      dataIndex: "clasificacion_atc_3",
      key: "clasificacion_atc_3",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Clasificación Act 4",
      dataIndex: "clasificacion_atc_4",
      key: "clasificacion_atc_4",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Clasificación Act 5",
      dataIndex: "clasificacion_atc_5",
      key: "clasificacion_atc_5",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Medidas",
      dataIndex: "medida",
      key: "medida",
      width: "200px",
    },
    {
      title: "Presentación",
      dataIndex: "presentacion",
      key: "presentacion",
      width: "200px",
    },
    {
      title: "Venta por fracción",
      dataIndex: "es_ventas_por_fraccion",
      key: "es_ventas_por_fraccion",
      render: (_, { es_ventas_por_fraccion }) => (es_ventas_por_fraccion ? <p>Si</p> : <p>No</p>),
      ellipsis: true,
      width: "200px",
    },

    {
      title: "Master pack de producto",
      dataIndex: "master_pack_producto",
      key: "master_pack_producto",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Condiciones de almacenamiento",
      dataIndex: "condicion_almacenamiento",
      key: "condicion_almacenamiento",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Código de barra por caja EAN14/EAN128",
      dataIndex: "codigo_de_barra_caja_ean14_ean128",
      key: "codigo_de_barra_caja_ean14_ean128",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Código de barra por unidad EAN13",
      dataIndex: "cod_codigo_de_barra_unidad_ean13",
      key: "cod_codigo_de_barra_unidad_ean13",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Indicaciones",
      dataIndex: "indicaciones",
      key: "indicaciones",
      ellipsis: true,
      onCell: () => {
        return {
          style: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: 180,
          },
        };
      },
      width: "200px",
    },
    {
      title: "Posología",
      dataIndex: "posologia",
      key: "posologia",
      ellipsis: true,
      onCell: () => {
        return {
          style: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: 180,
          },
        };
      },
      width: "200px",
    },
    {
      title: "Precauciones de uso",
      dataIndex: "precauciones_de_uso",
      key: "precauciones_de_uso",
      ellipsis: true,
      onCell: () => {
        return {
          style: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: 180,
          },
        };
      },
      width: "200px",
    },
    {
      title: "Antídoto",
      dataIndex: "antidoto",
      key: "antidoto",
      ellipsis: true,
      width: "200px",
      onCell: () => {
        return {
          style: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: 180,
          },
        };
      },
    },
    {
      title: "Patología a la que aplica",
      dataIndex: "patologia_a_la_que_aplica",
      key: "patologia_a_la_que_aplica",
      ellipsis: true,
      width: "200px",
      onCell: () => {
        return {
          style: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: 180,
          },
        };
      },
    },
    {
      title: "Contraindicaciones",
      dataIndex: "contraindicaciones",
      key: "contraindicaciones",
      ellipsis: true,
      width: "200px",
      onCell: () => {
        return {
          style: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: 180,
          },
        };
      },
    },
    {
      title: "Efectos secundarios",
      dataIndex: "efectos_secundarios",
      key: "efectos_secundarios",
      ellipsis: true,
      width: "200px",
      onCell: () => {
        return {
          style: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: 180,
          },
        };
      },
    },
    {
      title: "Politica de devolución por caducidad",
      dataIndex: "politica_devolucion_caducidad",
      key: "politica_devolucion_caducidad",
      ellipsis: true,
      width: "200px",
      onCell: () => {
        return {
          style: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: 180,
          },
        };
      },
    },
    {
      title: "Tipo de mercado",
      dataIndex: "tipo_mercado",
      key: "tipo_mercado",
      ellipsis: true,
      width: "200px",
      onCell: () => {
        return {
          style: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: 180,
          },
        };
      },
    },
    {
      title: "Forma de aplicacón",
      dataIndex: "forma_aplicacion",
      key: "forma_aplicacion",
      ellipsis: true,
      width: "200px",
      onCell: () => {
        return {
          style: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: 180,
          },
        };
      },
    },
    {
      title: "Fecha de lanzamiento",
      dataIndex: "fecha_lanzamiento",
      key: "fecha_lanzamiento",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Grupo objetivo",
      dataIndex: "grupo_objetivo",
      key: "grupo_objetivo",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Tipo de venta",
      dataIndex: "tipo_venta",
      key: "tipo_venta",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Tipo de exhibición",
      dataIndex: "tipo_exhibicion",
      key: "tipo_exhibicion",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Recomendaciones",
      dataIndex: "recomendaciones",
      key: "recomendaciones",
      ellipsis: true,
      onCell: () => {
        return {
          style: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: 180,
          },
        };
      },
      width: "200px",
    },
    {
      title: "Productos similares en el mercado",
      dataIndex: "productos_similares",
      key: "productos_similares",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "#Registro/Notificación sanitaria",
      dataIndex: "registro_sanitario",
      key: "registro_sanitario",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Fecha de vencimiento",
      dataIndex: "fecha",
      key: "fecha",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Tiempo de vida útil(meses)",
      dataIndex: "tiempo_vida_util_del_producto",
      key: "tiempo_vida_util_del_producto",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Graba iva",
      dataIndex: "es_graba_iva",
      key: "es_graba_iva",
      render: (_, { es_graba_iva }) => (es_graba_iva ? <p>Si</p> : <p>No</p>),
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Graba ice",
      dataIndex: "es_graba_ice",
      key: "es_graba_ice",
      render: (_, { es_graba_ice }) => (es_graba_ice ? <p>Si</p> : <p>No</p>),
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Impuesto verde",
      dataIndex: "es_impuesto_verde",
      key: "es_impuesto_verde",
      render: (_, { es_impuesto_verde }) => (es_impuesto_verde ? <p>Si</p> : <p>No</p>),
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Costo (Sin IVA)",
      dataIndex: "costo_sin_iva",
      key: "costo_sin_iva",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "PVF (Sin IVA)",
      dataIndex: "pvf_sin_iva",
      key: "pvf_sin_iva",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "PVP (Con IVA)",
      dataIndex: "pvp_sin_iva",
      key: "pvp_sin_iva",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "% Descuento producto nuevo",
      dataIndex: "descuento_producto_nuevo",
      key: "descuento_producto_nuevo",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "% Descuento 1",
      dataIndex: "descuento_1",
      key: "descuento_1",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "% Bonificación 1",
      dataIndex: "bonificacion",
      key: "bonificacion",
      ellipsis: true,
      width: "200px",
    },

    {
      title: "Alto Caja(cm)",
      dataIndex: "profundidad",
      key: "profundidad",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Ancho Caja(cm)",
      dataIndex: "ancho_caja_cm",
      key: "ancho_caja_cm",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Largo Caja(cm)",
      dataIndex: "largo_caja_cm",
      key: "largo_caja_cm",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Peso bruto Caja(gr)",
      dataIndex: "peso_bruto_gr",
      key: "peso_bruto_gr",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Peso Neto contenido Caja(gr)",
      dataIndex: "peso_neto_gr",
      key: "peso_neto_gr",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Alto Bulto(cm)",
      dataIndex: "profundida_cm_b",
      key: "profundida_cm_b",
      ellipsis: true,
      width: "200px",
    },
    {
      title: " Ancho Bulto(cm)",
      dataIndex: "ancho_caja_cm_b",
      key: "ancho_caja_cm_b",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Largo Bulto(cm)",
      dataIndex: "largo_caja_cm_b",
      key: "largo_caja_cm_b",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Peso bruto presentación primaria Bulto( gr.)",
      dataIndex: "peso_bruto_kg",
      key: "peso_bruto_kg",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Cantidad caja por Pallet",
      dataIndex: "cantidad_caja_x_pallet",
      key: "cantidad_caja_x_pallet",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Medida del pallet que usa el proveedor(1 x 1.2)",
      dataIndex: "medida_pallet",
      key: "medida_pallet",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Fotos",
      dataIndex: "imagenes",
      key: "imagenes",
      fixed: "right",
      width: "100px",
      height: "40px",
      render: (_, { imagenes }) => {
        let img = imagenes?.split(",");

        return (
          <>
            {img && (
              <Image.PreviewGroup>
                <Image width={40} height={30} src={`https://infofsg.com/allku/archivo/${img[0]}`} />
                <Image width={40} height={30} src={`https://infofsg.com/allku/archivo/${img[1]}`} />
                {/* <Image width={40} src={`http://localhost:8083/allku/archivo/${img[0]}`} />
              <Image width={40} src={`http://localhost:8083/allku/archivo/${img[1]}`} /> */}
              </Image.PreviewGroup>
            )}
          </>
        );
      },
    },
    {
      title: "Reg. San.",
      dataIndex: "llave_pdf",
      key: "llave_pdf",
      fixed: "right",
      width: "100px",
      align: "center",
      render: (_, { llave_pdf }) => {
        return (
          <div>
            <Button onClick={() => showModal(llave_pdf)} icon={<EyeFilled />}></Button>
          </div>
        );
      },
    },
    {
      title: "Estado",
      dataIndex: "cod_estado",
      key: "cod_estado",
      fixed: "right",
      align: "center",
      width: "125px",
      render: (_, record) => {
        function getContent(record) {
          /* eslint eqeqeq: 0 */
          return parseInt(fkIdPerfil) === 1 || parseInt(fkIdPerfil) === 3 ? (
            <>
              {_ === 4 ? (
                <Tag icon={<CheckCircleOutlined />} color='success'>
                  APROBADO
                </Tag>
              ) : (
                <Radio.Group onChange={(e) => handleRadio(e, record)} value={_} style={{ display: "flex", flexDirection: "column" }}>
                  {estados?.map((el) => (
                    <Radio value={el.cod_estado} key={el.cod_estado}>
                      {el.descripcion}
                    </Radio>
                  ))}
                </Radio.Group>
              )}
            </>
          ) : null;
        }
        return parseInt(fkIdPerfil) === 1 || parseInt(fkIdPerfil) === 3 ? (
          <Popover content={getContent(record)} title='Estado' trigger='click'>
            <EllipsisOutlined />
          </Popover>
        ) : (
          /* eslint eqeqeq: 0 */
          // return parseInt(fkIdPerfil) === 1 || parseInt(fkIdPerfil) === 3 ? (
          //   <>
          //     {_ === 4 ? (
          //       <Tag icon={<CheckCircleOutlined />} color='success'>
          //         APROBADO
          //       </Tag>
          //     ) : (
          //       <Radio.Group onChange={(e) => handleRadio(e, record)} value={_}>
          //         {estados?.map((el) => (
          //           <Radio value={el.cod_estado} key={el.cod_estado}>
          //             {el.descripcion}
          //           </Radio>
          //         ))}
          //       </Radio.Group>
          //     )}
          //   </>
          // )
          <div>
            {_ === 4 && (
              <Tag icon={<CheckCircleOutlined />} color='success'>
                APROBADO
              </Tag>
            )}
            {_ === 1 && (
              <Tag icon={<ClockCircleOutlined />} color='default'>
                PENDIENTE
              </Tag>
            )}
            {_ === 3 && (
              <Tag icon={<CloseCircleOutlined />} color='error'>
                RECHAZADO
              </Tag>
            )}
            {_ === 2 && (
              <Tag icon={<MinusCircleOutlined />} color='warning'>
                CON ERRORES
              </Tag>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Form>
        <Card title='PRODUCTOS' style={{ marginBottom: "5px", width: "100%" }}>
          <Form.Item label='Mostrar Productos por' name='tipo_show'>
            <Select options={opciones} onChange={handleList} />
          </Form.Item>
        </Card>
      </Form>

      {productos.length > 0 ? (
        <Table
          // title={() => "Lista de Productos"}
          pagination={{ defaultPageSize: 10 }}
          // pagination={false}

          bordered
          columns={columns}
          dataSource={productos}
          scroll={{ x: 900, y: "50vh" }}
          style={{ height: "65vh" }}
          // scroll={{ x: 900, y: 800 }}
          // style={{
          //   margin: "5px",
          //   top: "50px",
          //   height: "700px"
          // }}
          size='small'
          onRow={(record) => {
            return {
              onClick: (e) => {
                if (e.target.id === "nombre") {
                  record.largo_bulto = record.largo_caja_cm_b;
                  record.medida_del_pallet_que_usa_el_proveedor = record.medida_pallet;
                  record.cantidad_caja_por_pallet = record.cantidad_caja_x_pallet;
                  record.peso_bruto_presentacion_primaria_bulto = record.peso_bruto_kg;
                  record.ancho_bulto = record.ancho_caja_cm_b;
                  record.profundidad_bulto = record.profundida_cm_b;
                  record.peso_neto_contenido_caja = record.peso_neto_gr;
                  record.peso_bruto_caja = record.peso_bruto_gr;
                  record.largo_caja = record.largo_caja_cm;
                  record.ancho_caja = record.ancho_caja_cm;
                  record.profundidad_caja = record.profundidad;
                  record.pvf = record.pvf_sin_iva;
                  record.pvp = record.pvp_sin_iva;
                  record.costo = record.costo_sin_iva;
                  record.impuesto_verde = record.es_impuesto_verde ? 1 : 0;
                  record.graba_iva = record.es_graba_iva ? 1 : 0;
                  record.graba_ice = record.es_graba_ice ? 1 : 0;
                  record.fecha_de_vencimiento = moment(record.fecha);
                  record.registronotificacion_sanitaria = record.registro_sanitario;
                  record.forma_de_aplicacion = record.cod_forma_aplicacion;
                  record.fecha_de_lanzamiento_aa_mm_dd = moment(record.fecha_lanzamiento);
                  record.tipo_de_mercado = record.cod_tipo_mercado;
                  record.codigo_barra_caja_ean14_ean128 = record.codigo_de_barra_caja_ean14_ean128;
                  record.master_pack_de_producto = record.master_pack_producto;
                  record.condiciones_almacenamiento = record.cod_condicion_almacenamiento;
                  record.codigo_barra_unidad_ean13 = record.cod_codigo_de_barra_unidad_ean13;
                  record.ventas_por_fraccion = record.es_ventas_por_fraccion ? 1 : 0;
                  record.clasificacion_del_medicamento = record.cod_clasificacion;
                  record.nombre_del_producto = record.nombres;
                  // record.laboratorio_fabricante = record.cod_laboratorio;
                  record.principio_activo = record.cod_generico;
                  record.tipo_producto = record.cod_tipo_producto;
                  record.presentacion = record.cod_presentacion;
                  record.medida = record.cod_medida;
                  record.grupo_objetivo = record.cod_grupo_objetivo;
                  record.tipo_venta = record.cod_tipo_venta;
                  record.tipo_exhibicion = record.cod_tipo_exhibicion;
                  record.clasificacion_atc_1 = record.cod_clasificacion_atc_1;
                  record.clasificacion_atc_2 = record.cod_clasificacion_atc_2;
                  record.clasificacion_atc_3 = record.cod_clasificacion_atc_3;
                  record.clasificacion_atc_4 = record.cod_clasificacion_atc_4;
                  record.clasificacion_atc_5 = record.cod_clasificacion_atc_5;
                  record.es_envase_primario = record.es_envase_primario ? 1 : 0;
                  record.es_envase_secundario = record.es_envase_secundario ? 1 : 0;
                  record.proveedor = record.nombre_proveedor;
                  setRow(record);
                  showModal3();
                }
              },
            };
          }}
        />
      ) : (
        <Empty
          image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
          imageStyle={{
            height: 100,
          }}
          description={<span>Aún no se cargan los productos</span>}
        ></Empty>
      )}
      <PdfViewer url={url} onCancel={handleCancel} visible={showPdf} />

      <Modal
        title='Completar Aprobación del Producto'
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel2}
        footer={null}
        destroyOnClose={true}
        transitionName=''
      >
        <CompletarForm aprobado={aprobado} handleCancel2={handleCancel2} productos={productos} setProductos={setProductos} reload={reload} />
      </Modal>
      <Modal
        title={fkIdPerfil !== 1 ? "Editar Producto" : "Ver Producto"}
        visible={isModalOpen2}
        onOk={handleOk2}
        onCancel={handleCancel3}
        footer={null}
        transitionName=''
        width={1200}
        destroyOnClose={true}
        style={{
          top: 20,
          maxHeight: 100,
        }}
      >
        <TabsPage record={row} editar={1} handleCancel3={handleCancel3} setUpdate={setUpdate} />
      </Modal>
    </>
  );
};

export default Proveedor;
