import { fetchHelp, fetchHelpToken } from "../../../helper/fetch";
import Swal from "sweetalert2";
import { auth, loader, setTemporal } from "./login";
import { userModal } from "../ui/uiModal";

import { notification } from "antd";

export const startLogin = (values, setLoadings) => {
  return async (dispatch) => {
    setLoadings(true);
    const data = await fetchHelp("auth/login", values, "POST");
    const res = await data.json();
    if (!res.ok) {
      Swal.fire("Error", res.msg, "error");
    } else {
      localStorage.setItem("x-token", res.token);

      res.user.temporal
        ? dispatch(setTemporal(res.user.temporal.temp))
        : dispatch(setTemporal(res.user.temporal));

      delete res.user.temporal;
      dispatch(auth({ user: res.user }));
    }
    setLoadings(false);
  };
};

export const refreshLogin = () => {
  return async (dispatch) => {
    dispatch(loader(false));
    const token = localStorage.getItem("x-token");
    if (token) {
      const data = await fetchHelpToken("auth/revalidar", {}, "POST");
      const res = await data.json();

      if (res.ok) {
        res.user.temporal
          ? dispatch(setTemporal(res.user.temporal.temp))
          : dispatch(setTemporal(res.user.temporal));

        delete res.user.temporal;
        dispatch(auth({ user: res.user }));
      } else {
        localStorage.removeItem("x-token");
      }
    }
    dispatch(loader(true));
  };
};

export const startHash = (temp, navigate) => {
  return async (dispatch) => {
    const res = await fetchHelp(`usuarios/temporal/${temp}`, {}, "POST");
    const data = await res.json();

    if (data.ok) {
      localStorage.setItem("x-token", data.token);
      const user = data.temporal.usuario;
      dispatch(auth({ user: user }));
      !data.temporal
        ? dispatch(setTemporal(data.temporal))
        : dispatch(setTemporal(data.temporal.temp));
    } else {
      Swal.fire("Error", data.msg, "error");
      navigate("auth/login");
    }
  };
};

export const startNewPass = (values, handleCancel, form, setLoading) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await fetchHelpToken("auth/newpass", values, "POST");
    const data = await res.json();
    if (data.ok) {
      dispatch(setTemporal(null));
      Swal.fire("OK", data.msg, "success");
      handleCancel();
      form.resetFields();
    }
    setLoading(false);
  };
};

export const startUpdateInfo = (values, setLoader) => {
  return async (dispatch, getState) => {
    // const { cedula } = values;

    setLoader(true);
    // const validarCedula = await fetch(
    //   `https://srienlinea.sri.gob.ec/movil-servicios/api/v1.0/deudas/porIdentificacion/${cedula}`
    // );

    // if (!validarCedula.ok) {
    //   setLoader(false);
    //   return Swal.fire("Error", "Por favor verifique, La cédula no es correcta", "error");
    // }

    values.idPersona = getState().auth.credential.persona.idPersona;
    values.idUsuario = getState().auth.credential.idUsuario;
    const res = await fetchHelpToken("usuarios/actualizar", values, "POST");
    const data = await res.json();
    if (data.ok) {
      dispatch(auth({ user: data.user }));
      dispatch(setTemporal(null));
      Swal.fire("OK", data.msg, "success");
    } else {
      Swal.fire("Error", data.msg, "error");
      setLoader(false);
    }
  };
};

export const startUpdatePerson = (values, id, setUpdateTable, setLoader) => {
  return async (dispatch, getState) => {
    const { cedula } = values;

    setLoader(true);
    const validarCedula = await fetch(
      `https://srienlinea.sri.gob.ec/movil-servicios/api/v1.0/deudas/porIdentificacion/${cedula}`
    );

    if (!validarCedula.ok) {
      setLoader(false);
      return Swal.fire("Error", "Por favor verifique, La cédula no es correcta", "error");
    }

    const res = await fetchHelpToken(`personas/actualizar/${id}`, values, "POST");
    const data = await res.json();

    if (!data.ok) {
      setLoader(false);
      return Swal.fire("Error", data.msg, "error");
    } else {
      Swal.fire("OK", data.msg, "success");
      dispatch(userModal({ value: false }));
      setUpdateTable((updateTable) => !updateTable);
    }
    setLoader(false);

    /*values.idPersona = getState().auth.credential.persona.idPersona;
    const res = await fetchHelpToken("usuarios/actualizar", values, "POST");
    const data = await res.json();
    if (data.ok) {
      dispatch(auth({ user: data.user }));
      dispatch(setTemporal(null));
      Swal.fire("OK", data.msg, "success");
    }*/
  };
};

export const startResendEmail = (values, setLoading, loading) => {
  return async (dispatch, getState) => {
    setLoading({ ...loading, [values.key]: true });
    const res = await fetchHelpToken("auth/resend", values, "POST");
    const json = await res.json();

    if (json.ok) {
      notification.success({
        message: "Correo reenviado exitosamente",
        description: `${json.msg}`,
      });
      setLoading({ ...loading, [values.key]: false });
    } else if (!json.ok) {
      notification.error({
        message: "Error",
        description: `${json.msg}`,
      });
    }
  };
};
