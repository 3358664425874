const baseUrlAllku = process.env.REACT_APP_ALLKU_URL;

export const helperLlaves = (files) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = files;
      let llaves = [];

      const requests = data.map(async (el) => {
        const body = new FormData();

        body.append("uploadfile", el.originFileObj);
        const requestSettings = {
          body,
          method: "POST",
          /*headers: {
            Accept: "application/json",
          },*/
        };
        // return fetch(`https://infofsg.com/allku/proveedores/`, requestSettings).then(data => data.json());
        return fetch(`${baseUrlAllku}proveedores`, requestSettings).then((data) => data.json());
      });

      const result = await Promise.all(requests);

      result.forEach((item) => {
        llaves.push(item.llave);
      });

      resolve(llaves);
    } catch (error) {
      reject({ ok: error });
    }
  });
};
