import { FileExcelFilled } from "@ant-design/icons";
import * as XLSX from "xlsx";
import { Upload } from "antd";
import React, { useState } from "react";
import AfterFile from "./AfterFile";
import { useDispatch } from "react-redux";
import { loadHeader, loadTable } from "../../store/slices/proveedor/tableExcel";
import { getJsDateFromExcel } from "excel-date-to-js";
import Swal from "sweetalert2";

const { Dragger } = Upload;

const Drag = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState([]);
  const [list, setList] = useState(true);

  let dataExcel = [];

  const props = {
    name: file,
    multiple: false,

    onChange(e) {
      setFile(e.fileList);
      const reader = new FileReader();

      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[2];
        if (typeof wsname === "undefined") {
          setFile([]);
          return Swal.fire(
            "Error al cargar el archivo",
            "Recuerde usar la plantilla que se encuentra en la parte superior con el formato establecido!",
            "error"
          );
        }
        const ws = wb.Sheets[wsname];
        const datos = XLSX.utils.sheet_to_json(ws, { header: 1 });
        //datos[0].unshift("Imagenes");
        if (datos.length <= 0 || !datos || datos[0].length <= 55) {
          setFile([]);
          return Swal.fire(
            "Error al cargar el archivo",
            "Recuerde usar la plantilla que se encuentra en la parte superior con el formato establecido!",
            "error"
          );
        }
        if (datos[1].length <= 0) {
          setFile([]);
          return Swal.fire(
            "Error al cargar el archivo",
            "El archivo en la hoja Carga masiva no tiene productos agregados!",
            "error"
          );
        }
        //datos[0].unshift("Registro Sanitario");
        dispatch(loadHeader({ head: datos[0] }));

        for (let i = 1; i < datos.length; i++) {
          dataExcel.push({
            key: i.toString(),

            laboratorio_fabricante: datos[i][0] || "",
            pais: datos[i][1] || "",
            nombre_del_producto: datos[i][2] || "",
            tipo_de_producto: datos[i][3] || "",
            clasificacion_del_medicamento: datos[i][4] || "N/A",
            principioactivo: datos[i][5] || "N/A",
            clasificacion_atc_1: datos[i][6] || "N/A",
            clasificacion_atc_2: datos[i][7] || "N/A",
            clasificacion_atc_3: datos[i][8] || "N/A",
            clasificacion_atc_4: datos[i][9] || "N/A",
            clasificacion_atc_5: datos[i][10] || "N/A",
            medidas: datos[i][11] || "N/A",
            presentacion: datos[i][12] || "",
            venta_por_fraccion: datos[i][13] || "",
            master_pack_de_producto: datos[i][14] || "",
            condiciones_almacenamiento: datos[i][15] || "",
            codigo_de_barra_por_caja_ean14ean128: datos[i][16] || "",
            codigo_de_barra_por_unidad_ean13: datos[i][17] || "",
            indicaciones: datos[i][18] || "",
            posologia: datos[i][19] || "",
            precauciones_de_uso: datos[i][20] || "",
            antidoto: datos[i][21] || "",
            patologia_a_la_que_aplica: datos[i][22] || "",
            contraindicaciones: datos[i][23] || "",
            efectos_secundarios: datos[i][24] || "",
            politica_de_devolucion_por_caducidad: datos[i][25] || "",
            tipo_de_mercado: datos[i][26] || "",
            forma_de_aplicacion: datos[i][27] || "",
            fecha_de_lanzamiento_aa_mm_dd:
              typeof datos[i][28] === "undefined"
                ? ""
                : getJsDateFromExcel(datos[i][28]).toISOString().split("T")[0],
            grupo_objetivo: datos[i][29] || "",
            tipo_de_venta: datos[i][30] || "",
            tipo_de_exhibicion: datos[i][31] || "",
            recomendaciones: datos[i][32] || "",
            productos_similares_en_el_mercado: datos[i][33] || "",
            registronotificacion_sanitaria: datos[i][34] || "",
            fecha_vencimiento:
              typeof datos[i][35] === "undefined"
                ? ""
                : getJsDateFromExcel(datos[i][35]).toISOString().split("T")[0],
            tiempo_vida_util_del_producto: datos[i][36] || "",
            graba_iva: datos[i][37] || "",
            graba_ice: datos[i][38] || "",
            impuesto_verde: datos[i][39] || "",
            costo: datos[i][40] || "0",
            pvf: datos[i][41] || "",
            pvp: datos[i][42] || "",
            descuento_producto_nuevo: datos[i][43] || "0",
            descuento_1: datos[i][44] || "0",
            bonificacion: datos[i][45] || "0",
            alto_caja: datos[i][46] || "",
            ancho_caja: datos[i][47] || "",
            largo_caja: datos[i][48] || "",
            peso_bruto_caja: datos[i][49] || "",
            peso_neto_contenido_caja: datos[i][50] || "",
            alto_bulto: datos[i][51] || "",
            ancho_bulto: datos[i][52] || "",
            largo_bulto: datos[i][53] || "",
            peso_bruto_presentacion_primaria_bulto: datos[i][54] || "",
            cantidad_caja_por_pallet: datos[i][55] || "",
            medida_del_pallet_que_usa_el_proveedor: datos[i][56] || "",
            es_envase_primario: datos[i][57] || "",
            es_envase_secundario: datos[i][58] || "",
            temperatura_maxima: parseFloat(datos[i][59]) || "0",
            calificacion: datos[i][60] || ""
          });
        }

        dispatch(loadTable({ value: dataExcel }));
      };

      if (e.fileList[0]) {
        reader.readAsBinaryString(e.file);
      }
    },

    onDrop(e) {
      //console.log("Archivo subido", e.dataTransfer.files);
    },

    onRemove() {
      setFile([]);
      setList(false);
    },
  };

  return (
    <div>
      <Dragger
        {...props}
        beforeUpload={() => false}
        maxCount={1}
        accept='.xlsm'
        showUploadList={list}
      >
        <p className='ant-upload-drag-icon'>
          <FileExcelFilled style={{ color: "#73c94e" }} />
        </p>

        <p className='ant-upload-text'>
          Haga click o arrastre el archivo en esta área para subirlo
        </p>

        <p className='ant-upload-hint'>
          Carga unica de archivo excel para subida masiva de productos
        </p>
      </Dragger>

      {file.length > 0 && <AfterFile setFile={setFile} remove={props.onRemove} />}
    </div>
  );
};

export default Drag;
