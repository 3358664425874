import React from "react";
import { Form, Input, Button, Row, Col, Alert, Card } from "antd";
import { useDispatch } from "react-redux";
import { startUpdateInfo } from "./../../store/slices/auth/thunk";
import { MdSend } from "react-icons/md";
import { useState } from "react";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} es obligatorio!",
  types: {
    email: "${label} no es valido!",
    number: "${label} is not a valid number!",
  },
};

const FormUser = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const onFinish = (values) => {
    Swal.fire({
      title: "¿Estás seguro de guardar?",
      text: "Para futuros accesos al portal, es importante que memorices o anotes en un lugar seguro las credenciales de registro que utilices.",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No",
      confirmButtonText: "Sí",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(startUpdateInfo(values, setLoader));
      } else if (result.isDenied) {
      }
    });
  };

  return (
    <>
      <Alert
        message='Para acceder a todas las funcionalidades del sistema, por favor complete el siguiente formulario con su información requerida.'
        type='warning'
        showIcon
        style={{ marginBottom: 10 }}
      />

      <div style={{ marginBottom: 10, display: "flex", justifyContent: "center", width: "100wv" }}>
        <Form
          // {...layout}
          name='nest-messages'
          onFinish={onFinish}
          validateMessages={validateMessages}
          layout={"vertical"}
          className='form_user'
        >
          <Row wrap={true} gutter={[0, 5]}>
            <Col xs={24} lg={24}>
              <Card title='Datos Personales' type='inner' size='small'>
                <Row wrap={true} gutter={[5, 0]}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      name='cedula'
                      label='Cedula :'
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingrese su cédula",
                        },
                        {
                          pattern: /^[0-9]{10}$/,
                          message: "La cédula debe tener 10 números",
                        },
                      ]}
                      // rules={[
                      //   {
                      //     required: true,
                      //     validator: (_, value) =>
                      //       !value.trim().length === 0 &&
                      //       value.trim().length === 10 &&
                      //       value.match(ExpRegSoloNumeros) != null
                      //         ? Promise.resolve()
                      //         : Promise.reject(new Error("Cédula no valida!")),
                      //   },
                      // ]}
                    >
                      <Input placeholder='Escriba su número de cédula' maxLength={10} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row wrap={true} gutter={[5, 0]}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      name='apellidos'
                      label='Apellidos :'
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder='Escriba sus apellidos' />
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={12}>
                    <Form.Item
                      label='Nombres :'
                      name='nombres'
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder='Escriba sus nombres' />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      name='telefono'
                      label='Telefono :'
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder='Escriba su número de teléfono' />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      name='cargo'
                      label='Cargo :'
                      style={{ marginBottom: "10px" }}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder='Escriba su cargo' />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} lg={24}>
              <Card title='Credenciales ' type='inner' size='small'>
                <Form.Item
                  name='usuario'
                  label='Usuario :'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder='Escriba su usuario de acceso al sistema' autoComplete='off' />
                </Form.Item>

                <Form.Item
                  name='contraseña'
                  label='Contraseña :'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.Password
                    placeholder='Ingrese su contraseña'
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>
              </Card>
            </Col>
          </Row>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <Form.Item>
              <Button
                loading={loader}
                type='primary'
                htmlType='submit'
                icon={<MdSend style={{ position: "absolute", right: "0" }} size={22} />}
                block={true}
              >
                Guardar
              </Button>
            </Form.Item>
            {/* <Form.Item>
              <Button
                loading={loader}
                type='primary'
                htmlType='submit'
                icon={
                  <MdSend style={{ position: "absolute", right: "5px", width: "100%" }} size={22} />
                }
              >
                Guardar
              </Button>
            </Form.Item> */}
          </div>
        </Form>
      </div>
    </>
  );
};

export default FormUser;
