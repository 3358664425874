import { useState } from "react";


export const useTabsPage = () => {
  const [productoTipo, setProductoTipo] = useState(0);

  const handleTipeProduc = (e) => {
    if (parseInt(e) === 2) {
      setProductoTipo(e);
    } else {
      setProductoTipo(e);
    }
  };


  return {
    handleTipeProduc,
    productoTipo
  };
};