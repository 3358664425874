import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reload: true,
  productos: [],
};

export const productosReload = createSlice({
  name: "productos",
  initialState,
  reducers: {
    reloader: (state, action) => {
      state.reload = action.payload;
    },
    loadProducts: (state, action) => {
      state.productos = action.payload;
    },
    resetProducts: (state, action) => {
      state.productos = initialState;
    },
  },
});

export const { reloader, loadProducts, resetProducts } = productosReload.actions;
