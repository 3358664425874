import React, { useEffect } from "react";
import { Form, Input, Button, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { startUpdatePerson } from "./../../store/slices/auth/thunk";
import { MdCancel, MdSend } from "react-icons/md";
import { useState } from "react";

const layout = {
  labelCol: {
    span: 14,
  },
  wrapperCol: {
    span: 23,
  },
};

export let ExpRegSoloNumeros = "^[0-9]+$";
/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} es obligatorio!",
  types: {
    email: "${label} no es valido!",
    number: "${label} is not a valid number!",
  },
};

const EditUser = ({ editarPersona, setUpdateTable, handleCancel }) => {
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const { isOpen } = useSelector((state) => state.ui);

  const dispatch = useDispatch();

  const onFinish = (values) => {
    dispatch(startUpdatePerson(values, editarPersona.idPersona, setUpdateTable, setLoader));
  };

  useEffect(() => {
    form.setFieldsValue(editarPersona);
  }, [isOpen, editarPersona, form]);

  return (
    <Form
      {...layout}
      form={form}
      name='nest-messages'
      onFinish={onFinish}
      validateMessages={validateMessages}
      layout={"vertical"}
      initialValues={editarPersona}
    //size={"small"}
    >
      <Row wrap={true}>
        <Col xs={24} lg={24}>
          <Form.Item
            label='Nombres :'
            style={{ marginBottom: "5px" }}
            name='nombres'
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder='Escriba sus nombres' />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "5px" }}
            name='apellidos'
            label='Apellidos :'
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder='Escriba sus apellidos' />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "5px" }}
            name='correo'
            rules={[
              {
                required: true,
                type: "email",
              },
            ]}
            label='Correo :'
          >
            <Input placeholder='Escriba su correo electronico' />
          </Form.Item>

          <Form.Item
            name='telefono'
            label='Teléfono :'
            style={{ marginBottom: "5px" }}
            rules={[
              {
                required: true,
                validator: (_, value) =>
                  /* eslint eqeqeq: 0 */
                  !value.trim().length == 0 && value.match(ExpRegSoloNumeros) != null
                    ? Promise.resolve()
                    : Promise.reject(new Error("Teléfono no valido!")),
              },
            ]}
          >
            <Input placeholder='Escriba su número de teléfono' />
          </Form.Item>
        </Col>
        <Col xs={24} lg={24}>
          <Form.Item
            name='cedula'
            label='Cedula :'
            style={{ marginBottom: "5px" }}
            rules={[
              {
                required: true,
                validator: (_, value) =>
                  /* eslint eqeqeq: 0 */
                  !value.trim().length == 0 &&
                    value.trim().length == 10 &&
                    value.match(ExpRegSoloNumeros) != null
                    ? Promise.resolve()
                    : Promise.reject(new Error("Cédula no valida!")),
              },
            ]}
          >
            <Input placeholder='Escriba su número de cédula' />
          </Form.Item>

          <Form.Item
            name='cargo'
            label='Cargo :'
            style={{ marginBottom: "5px" }}
            rules={[
              {
                required: true,
                validator: (_, value) =>
                  /* eslint eqeqeq: 0 */
                  !value.trim().length == 0
                    ? Promise.resolve()
                    : Promise.reject(new Error("El cargo es obligatorio!")),
              },
            ]}
          >
            <Input placeholder='Escriba su cargo' />
          </Form.Item>

          {/*Si existe usuario solo renderizar pass y user*/}

          <Form.Item
            name='usuario'
            label='Usuario :'
            style={{ marginBottom: "5px" }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder='Escriba un usuario de acceso' />
          </Form.Item>
        </Col>
      </Row>
      <div className='butonDrag'>
        <Form.Item>
          <Button
            disabled={loader}
            onClick={() => handleCancel()}
            style={{ marginRight: "10px", width: "150px" }}
            icon={
              <MdCancel
                style={{ position: "absolute", right: "10px", color: "#6a2831" }}
                size={22}
              />
            }
          >
            Cancelar
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            loading={loader}
            type='primary'
            htmlType='submit'
            icon={<MdSend style={{ position: "absolute", right: "10px" }} size={22} />}
            style={{ width: "150px" }}
          >
            Actualizar
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default EditUser;
