import { Drawer, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import logo from "../auth/logoFSGWhite.svg";

import Sidebar from "./Sidebar";

import { useDispatch, useSelector } from "react-redux";
import { userModal } from "../../store/slices/ui/uiModal";
import OptionsApp from "./OptionsApp";
import Notification from "./Notification";

const NavBar = () => {
  const dispatch = useDispatch();
  const { credential } = useSelector((state) => state.auth);
  const { fkIdPerfil } = credential;

  const { isSider } = useSelector((state) => state.ui);

  return (
    <div>
      <nav
        className='navbar'
        style={{ backgroundColor: "#6a2831", position: "fixed", zIndex: 100, width: "100%" }}
      >
        <div className='item1'>
          <div>
            <Button
              className='menu'
              icon={<MenuOutlined />}
              onClick={() => dispatch(userModal({ modal: true }))}
            />
          </div>
          <div>
            <a href='/'>
              <img src={logo} className='logo' alt='logo' />
            </a>
          </div>
        </div>
        <Drawer
          title='Opciones'
          placement='left'
          onClose={() => dispatch(userModal({ modal: false }))}
          visible={isSider}
        >
          <Sidebar />
        </Drawer>

        <div className='other'>
          {fkIdPerfil === 1 && <Notification />}
          <OptionsApp />
        </div>
      </nav>
    </div>
  );
};
export default NavBar;
