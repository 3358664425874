import React from "react";
import { Layout, Card } from "antd";

import { Route, Routes, useLocation } from "react-router-dom";
import Proveedor from "../proveedor/Proveedor";
import Sidebar from "../ui/Sidebar";
import Usuarios from "../usuarios/Usuarios";
import AgregarUsuario from "../usuarios/AgregarUsuario";

import NavBar from "../ui/Navbar";

import { useSelector } from "react-redux";
import NewPass from "../auth/NewPass";
import FormUser from "./../usuarios/FormUser";
import TabsPage from "../proveedor/TabsPage";
import Drag from "./../proveedor/Drag";
import excel from "../../../src/assets/FORMATO.xlsm";
import pdf from "../../../src/assets/Guia.pdf";
import { useEffect } from "react";
import ViewProducts from "../productos/ViewProducts";
import ViewProductsCompleted from "../productos/ViewProductsCompleted";
import FormProduct from "../productos/FormProduct";

export const AppProveedores = () => {
  const { Sider, Content } = Layout;
  const { temp, credential } = useSelector((state) => state.auth);
  const { usuario, fkIdPerfil } = credential;
  const location = useLocation();

  let lastVisitedUrl = location?.pathname;
  let count = lastVisitedUrl.split("/").length - 1;

  if (count === 1) {
    lastVisitedUrl = "/app" + lastVisitedUrl;
  }

  useEffect(() => {
    localStorage.setItem("lastVisitedUrl", lastVisitedUrl ? lastVisitedUrl : "/");
  }, [location.pathname, lastVisitedUrl]);

  return (
    <Layout>
      <NavBar />

      <Layout>
        {!temp && (
          <Sider
            theme='light'
            breakpoint='lg'
            collapsedWidth={1}
            style={{
              height: "100vh",
              position: "fixed",
              left: 0,
              top: 77,
              bottom: 0,
            }}
          >
            <Sidebar />
          </Sider>
        )}

        <Content className={`content ${temp && "content_web"}`}>
          {temp ? (
            usuario ? (
              <NewPass title='Para usar las opciones debe actualizar la contraseña' />
            ) : (
              <div style={{ padding: "20px" }}>
                {/* <h2>Completar registro</h2> */}
                <Card>
                  <FormUser />
                </Card>
              </div>
            )
          ) : (
            <Routes>
              <Route
                // path='usuarios'
                path='usuarios'
                element={
                  <div>
                    <Usuarios />
                    {(fkIdPerfil === 1 || fkIdPerfil === 3) && <AgregarUsuario />}
                  </div>
                }
              />
              <Route
                path='productos'
                element={
                  <div style={{ margin: "10px" }}>
                    <Proveedor />
                    {/*<NewProveedorModal />*/}
                  </div>
                }
              />
              <Route
                path='agregar'
                element={
                  <div style={{ backgroundColor: "white", margin: "10px", height: "auto" }}>
                    {/* <h2 style={{ margin: "20px" }}>Nuevo producto</h2> */}
                    <Card title='Nuevo producto'>
                      <TabsPage />
                    </Card>
                  </div>
                }
              />
              <Route
                path='/cargar'
                element={
                  <div style={{ margin: "20px" }}>
                    <h2>Cargar nuevos productos</h2>
                    <p>
                      <a href={excel} download='FORMATO.xlsm'>
                        Descargar plantilla
                        <span style={{ color: "blue", cursor: "pointer" }}> Excel </span>
                      </a>
                      <br />
                      <br />
                      <a href={pdf} download='Guia.pdf'>
                        ¿ Desea conocer el proceso para el manejo de la plantilla de Excel ?
                        <span style={{ color: "blue", cursor: "pointer" }}> Descargar guía </span>
                      </a>
                    </p>
                    <Drag />
                  </div>
                }
              />
              <Route
                path='/crear'
                element={
                  <div style={{ margin: "10px" }}>
                    {
                      <Card title='Crear Producto'>
                        <FormProduct tipo={1} />
                      </Card>
                    }
                  </div>
                }
              />

              <Route path='/editar' element={<div style={{ margin: "10px" }}>{<ViewProducts />}</div>} />

              <Route path='/completar' element={<div style={{ margin: "10px" }}>{<ViewProductsCompleted />}</div>} />
            </Routes>
          )}
        </Content>
      </Layout>
    </Layout>
  );
};
