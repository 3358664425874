import React, { useEffect, useState } from "react";
import { Drawer, Button, Space, Badge } from "antd";
import { MdNotifications } from "react-icons/md";
import { fetchHelpToken } from "../../helper/fetch";
import { useSelector } from "react-redux";
import ListarNotificacion from "./ListarNotificacion";
import { useCallback } from "react";

const Notification = () => {
  const [visible, setVisible] = useState(false);
  const [pendientes, setPendientes] = useState([]);
  const { reload } = useSelector((state) => state.storeReload);

  const { credential } = useSelector((state) => state.auth);
  const { cod_luc } = credential;

  const getPendientes = useCallback(async () => {
    const res = await fetchHelpToken("productos/pendientes", { cod_luc }, "POST");
    const data = await res.json();
    if (data.ok) {
      setPendientes(data.pendientes);
    }
  }, [cod_luc]);

  const showDefaultDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    getPendientes();
  }, [reload, getPendientes]);

  return (
    <>
      <Space>
        <Button
          onClick={showDefaultDrawer}
          style={{
            border: "none",
            background: "#6a2831",
          }}
        >
          <Badge count={pendientes > 0 ? pendientes : 0}>
            <MdNotifications
              size={24}
              style={{ backgroundColor: "white", borderRadius: "50px", color: "#6a2831" }}
            />
          </Badge>
        </Button>
      </Space>
      <Drawer
        title='Pendientes'
        placement='right'
        closable={true}
        size={30}
        onClose={onClose}
        visible={visible}
      >
        <ListarNotificacion onClose={onClose} />
      </Drawer>
    </>
  );
};

export default Notification;
