import { Provider } from "react-redux";
import AppRoute from "./routes/AppRoute";
import { store } from "./store/store";


function App() {
  return (
    <div className='dashboard'>
      <Provider store={store}>
        <AppRoute />
      </Provider>
    </div>
  );
}

export default App;
