import React, { useState } from "react";
import { Menu } from "antd";
import { AppstoreOutlined, UserOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userModal } from "../../store/slices/ui/uiModal";
import { useEffect } from "react";
import { useCallback } from "react";
import { useMemo } from "react";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const Sidebar = () => {
  const { credential, temp } = useSelector((state) => state.auth);
  const { fkIdPerfil } = credential;
  const [url, setUrl] = useState("");
  const [openKeys, setOpenKeys] = useState([]);
  const rootSubmenuKeys = useMemo(() => ["sub1", "sub2"], []);
  const dispatch = useDispatch();

  let location = useLocation();

  const [array, setArray] = useState([]);

  const onOpenChange = useCallback(
    (keys) => {
      const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

      if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        setOpenKeys(keys);
      } else {
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
      }
    },
    [openKeys, rootSubmenuKeys]
  );

  const memoizedPushToArray = useCallback(
    (value) => {
      setArray((prevArray) => [...prevArray, value]);
    },
    [setArray]
  );

  useEffect(() => {
    let a = location.pathname.split("/");
    if (window.screen.width >= 992) {
      if (a[2] === "usuarios") {
        // array.indexOf("sub1") === -1 && array.push("sub1");
        // onOpenChange(array);
        if (array.indexOf("sub1") === -1) {
          memoizedPushToArray("sub1");
          onOpenChange([...openKeys, "sub1"]);
        }
        setUrl(a[2]);
      } else if (
        a[2] === "productos" ||
        a[2] === "agregar" ||
        a[2] === "cargar" ||
        a[2] === "editar" ||
        a[2] === "completar" ||
        a[2] === "crear"
      ) {
        // array.indexOf("sub2") === -1 && array.push("sub2");
        // onOpenChange(array);
        if (array.indexOf("sub2") === -1) {
          memoizedPushToArray("sub2");
          onOpenChange([...openKeys, "sub2"]);
        }
        setUrl(a[2]);
      }
    }
  }, [location.pathname, memoizedPushToArray, array, openKeys, onOpenChange]);

  const items = [
    getItem(<Link to=''>Usuarios</Link>, "sub1", <UserOutlined />, [
      getItem(<Link to='/app/usuarios'>Administrar Usuarios</Link>, "usuarios"),
    ]),
    getItem(<Link to=''>Productos</Link>, "sub2", <AppstoreOutlined />, [
      (fkIdPerfil === 1 ||
        fkIdPerfil === 2 ||
        fkIdPerfil === 3 ||
        fkIdPerfil === 6 ||
        fkIdPerfil === 7 ||
        fkIdPerfil === 8) &&
        getItem(<Link to='/app/productos'>Listar Productos</Link>, "productos"),
      (fkIdPerfil === 2 || fkIdPerfil === 3 || fkIdPerfil === 7) &&
        getItem(<Link to='/app/agregar'>Agregar Productos</Link>, "agregar"),
      (fkIdPerfil === 2 || fkIdPerfil === 3 || fkIdPerfil === 7) &&
        getItem(<Link to='/app/cargar'>Cargar Productos</Link>, "cargar"),
      (fkIdPerfil === 1 || fkIdPerfil === 5 || fkIdPerfil === 3) &&
        getItem(<Link to='/app/crear'>Crear Producto</Link>, "crear"),
      (fkIdPerfil === 1 || fkIdPerfil === 3 || fkIdPerfil === 5 || fkIdPerfil === 7) &&
        getItem(<Link to='/app/editar'>Editar Aprobados</Link>, "editar"),
      (fkIdPerfil === 6 || fkIdPerfil === 3) && getItem(<Link to='/app/completar'>Completar Registro</Link>, "completar"),
    ]),
  ];

  return (
    <>
      {!temp && (
        <Menu
          mode='inline'
          openKeys={openKeys}
          onClick={() => dispatch(userModal({ modal: false }))}
          onOpenChange={onOpenChange}
          className='container'
          items={items}
          selectedKeys={[url]}
        />
      )}
    </>
  );
};

export default Sidebar;
