import { Modal } from "antd";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const PdfViewer = ({ url, onCancel, visible }) => {
  // const docs = [{ uri: `http://localhost:8083/allku/archivo/${url}` }];
  const docs = [{ uri: `https://infofsg.com/allku/archivo/${url}` }];
  //let a = await fetch(`https://infofsg.com/allku/archivo/${url}`, { credentials: 'include' });
  return (
    <Modal
      onCancel={onCancel}
      visible={visible}
      width={"50%"}
      bodyStyle={{ height: 600, overflowY: "auto" }}
      style={{ top: 20 }}
      footer={null}
      transitionName=''
    >
      <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
    </Modal>
  );
};
export default PdfViewer;
