import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { startHash } from "../../store/slices/auth/thunk";
import Loader from "../ui/Loader";

const ValidateHash = () => {
  let { temp } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startHash(temp, navigate));
  }, [dispatch, navigate, temp]);

  return (
    <div>
      <Loader />
    </div>
  );
};

export default ValidateHash;
