import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  isSider: false,
  producto: {
    ancho_bulto: null,
    ancho_caja: null,
    antidoto: null,
    bonificacion: null,
    cantidad_caja_por_pallet: null,
    clasificacion_atc_1: null,
    clasificacion_atc_2: null,
    clasificacion_atc_3: null,
    clasificacion_atc_4: null,
    clasificacion_atc_5: null,
    clasificacion_del_medicamento: null,
    codigo_barra_caja_ean14_ean128: null,
    codigo_barra_unidad_ean13: null,
    condiciones_almacenamiento: null,
    contraindicaciones: null,
    costo: null,
    descuento_1: null,
    descuento_producto_nuevo: null,
    efectos_secundarios: null,
    fecha_de_lanzamiento_aa_mm_dd: null,
    fecha_de_vencimiento: null,
    file_registro_sanitario: null,
    forma_de_aplicacion: null,
    graba_ice: null,
    graba_iva: null,
    grupo_objetivo: null,
    image: null,
    impuesto_verde: null,
    indicaciones: null,
    laboratorio_fabricante: null,
    largo_bulto: null,
    largo_caja: null,
    master_pack_de_producto: null,
    medida: null,
    medida_del_pallet_que_usa_el_proveedor: null,
    nombre_del_producto: null,
    pais: null,
    patologia_a_la_que_aplica: null,
    peso_bruto_caja: null,
    peso_bruto_presentacion_primaria_bulto: null,
    peso_neto_contenido_caja: null,
    politica_devolucion_caducidad: null,
    posologia: null,
    precauciones_de_uso: null,
    presentacion: null,
    principio_activo: null,
    productos_similares: null,
    profundidad_bulto: null,
    profundidad_caja: null,
    proveedor: null,
    pvf: null,
    pvp: null,
    recomendaciones: null,
    registronotificacion_sanitaria: null,
    ruc_proveedor: null,
    tiempo_vida_util_del_producto: null,
    tipo_de_mercado: null,
    tipo_exhibicion: null,
    tipo_producto: null,
    tipo_venta: null,
    ventas_por_fraccion: null,
  },
  tabKey: 1,
};

export const uiModal = createSlice({
  name: "modal",
  initialState,
  reducers: {
    userModal: (state, action) => {
      state.isOpen = action.payload.value;
      state.isSider = action.payload.modal;
    },
    finishForm: (state, action) => {
      state.producto = { ...state.producto, ...action.payload };
    },
    setabKey: (state, action) => {
      state.tabKey = action.payload;
    },
  },
});

export const { userModal, finishForm, setabKey } = uiModal.actions;
