import { Button, Form, Input, InputNumber, Popconfirm, Table, Typography, Upload } from "antd";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateTable } from "../../store/slices/proveedor/tableExcel";
import {
  EditFilled,
  SaveFilled,
  CloseCircleFilled,
  FilePdfFilled,
  FileImageFilled,
} from "@ant-design/icons";
import { startAddMasiveProduct } from "../../store/slices/productos/thunk";
import Swal from "sweetalert2";
import { MdCancel, MdSave } from "react-icons/md";
import { helperLlaves } from "./helperLlaves";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing &&
        (dataIndex === "nombre_del_producto" ||
          dataIndex === "tipo_de_producto" ||
          dataIndex === "codigo_de_barra_por_caja_ean14ean128" ||
          dataIndex === "codigo_de_barra_por_unidad_ean13" ||
          dataIndex === "tipo_de_mercado" ||
          dataIndex === "forma_de_aplicacion" ||
          dataIndex === "grupo_objetivo" ||
          dataIndex === "tipo_de_venta" ||
          dataIndex === "fecha_de_lanzamiento_aa_mm_dd" ||
          dataIndex === "tipo_de_exhibicion" ||
          dataIndex === "tiempo_vida_util_del_producto" ||
          dataIndex === "registronotificacion_sanitaria" ||
          dataIndex === "fecha_vencimiento" ||
          dataIndex === "graba_ice" ||
          dataIndex === "graba_iva" ||
          dataIndex === "impuesto_verde" ||
          dataIndex === "pvf" ||
          dataIndex === "pvp" ||
          dataIndex === "alto_caja" ||
          dataIndex === "ancho_caja" ||
          dataIndex === "largo_caja" ||
          dataIndex === "peso_bruto_caja" ||
          dataIndex === "alto_bulto" ||
          dataIndex === "ancho_bulto" ||
          dataIndex === "largo_bulto") ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Ingrese ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const AfterFile = ({ remove, setFile }) => {
  const { datos } = useSelector((state) => state.table);
  const { header } = useSelector((state) => state.table);
  const [editingKey, setEditingKey] = useState("");
  const { credential } = useSelector((state) => state.auth);
  const { idUsuario } = credential;
  const [loadings, setLoadings] = useState(false);

  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  let columns = [];
  const dispatch = useDispatch();

  const request = () => {

  };

  header.map((el, index) =>
    columns.push({
      title: el,
      dataIndex: el
        .toLowerCase()
        .replace(/ *\([^)]*\) */g, "")
        .normalize("NFD")
        .replace(/ /g, "_")
        .replace(/[^a-zA-Z_0-9]/g, ""),
      editable: "true",
      key: index,
      width: "200px",
      ellipsis: true,
    })
  );

  columns.push(
    {
      width: 70,
      fixed: "right",
      title: "Acción",
      dataIndex: "operation",
      textWrap: "ellipsis" | "word-break",
      render: (_, record) => {
        const editable = isEditing(record);

        return editable ? (
          <span style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              <SaveFilled />
            </Typography.Link>
            <Popconfirm title='Desea cancelar?' onConfirm={cancel}>
              <CloseCircleFilled style={{ color: "red" }} />
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
            style={{ marginLeft: "15px" }}
          >
            <EditFilled />
          </Typography.Link>
        );
      },
    },
    {
      title: "Reg. San.",
      fixed: "right",
      dataIndex: "note",
      onCell: () => {
        return {
          style: {
            whiteSpace: "nowrap",
            maxWidth: 80,
          },
        };
      },
      render: (_, record) => {
        return (
          <Form form={form1} onFinish={onCreate}>
            <br />
            <Form.Item name={`pdf${record.key}`} valuePropName={`pdf${record.key}`}>
              <Upload
                accept='.pdf'
                maxCount={1}
                customRequest={request}
                //beforeUpload={() => false}
                beforeUpload={(file, fileList) => {
                  return new Promise((resolve, reject) => {
                    const isLt5M = file.size / 1024 / 1024 <= 4;
                    if (!isLt5M) {
                      Swal.fire("Error", "El archivo excede el tamaño permitido(4MB)", "warning");
                      resolve(true);
                      return;
                    };
                    resolve(false);

                  });
                }}
                name={`pdf${record.key}`}
              >
                <FilePdfFilled
                  style={{
                    cursor: "pointer",
                    fontSize: "20px",
                    color: "#B22222",
                  }}
                />
              </Upload>
            </Form.Item>
          </Form>
        );
      },
    },
    {
      title: "Imagenes",
      fixed: "right",
      dataIndex: "note",
      width: 90,
      onCell: () => {
        return {
          style: {
            whiteSpace: "nowrap",
            maxWidth: 80,
          },
        };
      },
      render: (_, record) => {
        return (
          <Form form={form1} onFinish={onCreate}>
            <br />
            <Form.Item name={`img${record.key}`} valuePropName={`img${record.key}`}>
              <Upload
                accept='.png,.jpg,.jpeg,.svg,.tiff,.psd,.webp'
                maxCount={2}
                customRequest={request}
                //beforeUpload={() => false}
                beforeUpload={(file, fileList) => {
                  return new Promise((resolve, reject) => {
                    const isLt5M = file.size / 1024 / 1024 <= 4;
                    if (!isLt5M) {
                      Swal.fire("Error", "La imagen excede el tamaño permitido(4MB)", "warning");
                      resolve(true);
                      return;
                    };
                    resolve(false);
                  });
                }}
                name={`img${record.key}`}

              >
                <FileImageFilled
                  style={{
                    cursor: "pointer",
                    fontSize: "20px",
                    color: "#1E90FF",
                  }}
                />
              </Upload>
            </Form.Item>
          </Form>
        );
      },
    }
  );

  const edit = (record) => {
    form.setFieldsValue({
      proveedor: "",
      ruc_proveedor: "",
      laboratorio_fabricante: "",
      pais: "",
      nombre_del_producto: "",
      tipo_de_producto: "",
      clasificacion_del_medicamento: "",
      principioactivo: "",
      clasificacion_atc_1: "",
      clasificacion_atc_2: "",
      clasificacion_atc_3: "",
      clasificacion_atc_4: "",
      clasificacion_atc_5: "",
      medidas: "",
      presentacion: "",
      venta_por_fraccion: "",
      master_pack_de_producto: "",
      condiciones_almacenamiento: "",
      codigo_de_barra_por_caja_ean14ean128: "",
      codigo_de_barra_por_unidad_ean13: "",
      indicaciones: "",
      posologia: "",
      precauciones_de_uso: "",
      antidoto: "",
      patologia_a_la_que_aplica: "",
      contraindicaciones: "",
      efectos_secundarios: "",
      politica_de_devolucion_por_caducidad: "",
      tipo_de_mercado: "",
      forma_de_aplicacion: "",
      fecha_de_lanzamiento_aa_mm_dd: "",
      grupo_objetivo: "",
      tipo_de_venta: "",
      tipo_de_exhibicion: "",
      recomendaciones: "",
      productos_similares_en_el_mercado: "",
      registronotificacion_sanitaria: "",
      fecha_vencimiento: "",
      tiempo_vida_util_del_producto: "",
      graba_iva: "",
      graba_ice: "",
      impuesto_verde: "",
      costo: "",
      pvf: "",
      pvp: "",
      descuento_producto_nuevo: "",
      descuento_1: "",
      bonificacion: "",
      alto_caja: "",
      ancho_caja: "",
      largo_caja: "",
      peso_bruto_caja: "",
      peso_neto_contenido_caja: "",
      alto_bulto: "",
      ancho_bulto: "",
      largo_bulto: "",
      peso_bruto_presentacion_primaria_bulto: "",
      cantidad_caja_por_pallet: "",
      medida_del_pallet_que_usa_el_proveedor: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const isEditing = (record) => record.key === editingKey;

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...datos];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });

        dispatch(updateTable({ update: newData }));
        setEditingKey("");
      } else {
        newData.push(row);
        dispatch(updateTable({ update: newData }));
        setEditingKey("");
      }
    } catch (errInfo) {
      Swal.fire("Error!", "Complete los campos obligatorios", "warning");
    }
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,

      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const onCreate = async () => {
    let formData = new FormData();
    let files = form1.getFieldValue();

    for (let i = 0; i < datos.length; i++) {
      if (datos[i].nombre_del_producto.trim() === "") {
        Swal.fire("Error", `El nombre del producto: ${datos[i].key} está vacio`, "error");
        edit(datos[i]);
        i = datos.length;
        return;
      }
      if (datos[i].tipo_de_producto.trim() === "") {
        Swal.fire("Error", `El tipo del producto: ${datos[i].key} está vacio`, "error");
        edit(datos[i]);
        i = datos.length;
        return;
      }

      if (datos[i].codigo_de_barra_por_caja_ean14ean128 === "") {
        Swal.fire(
          "Error",
          `El codigo_barra_caja_ean14_ean128: ${datos[i].key} está vacio`,
          "error"
        );
        edit(datos[i]);
        i = datos.length;
        return;
      }
      if (datos[i].codigo_de_barra_por_unidad_ean13 === "") {
        Swal.fire("Error", `El codigo_barra_unidad_ean13: ${datos[i].key} está vacio`, "error");
        edit(datos[i]);
        i = datos.length;
        return;
      }
      //tipo_de_mercado
      if (datos[i].tipo_de_mercado === "") {
        Swal.fire("Error", `El tipo_de_mercado: ${datos[i].key} está vacio`, "error");
        edit(datos[i]);
        i = datos.length;
        return;
      }

      // peso_bruto_caja alto_bulto ancho_bulto largo_bulto

      if (datos[i].forma_de_aplicacion === "") {
        Swal.fire("Error", `El forma_de_aplicacion: ${datos[i].key} está vacio`, "error");
        edit(datos[i]);
        i = datos.length;
        return;
      }
      if (datos[i].grupo_objetivo === "") {
        Swal.fire("Error", `El grupo_objetivo: ${datos[i].key} está vacio`, "error");
        edit(datos[i]);
        i = datos.length;
        return;
      }
      if (datos[i].tipo_de_venta === "") {
        Swal.fire("Error", `El tipo_de_venta: ${datos[i].key} está vacio`, "error");
        edit(datos[i]);
        i = datos.length;
        return;
      }
      if (datos[i].fecha_de_lanzamiento_aa_mm_dd === "") {
        Swal.fire("Error", `La fecha_de_lanzamiento_aa_mm_dd: ${datos[i].key} está vacio`, "error");
        edit(datos[i]);
        i = datos.length;
        return;
      }

      if (datos[i].tipo_de_exhibicion === "") {
        Swal.fire("Error", `El tipo_de_exhibicion : ${datos[i].key} está vacio`, "error");
        edit(datos[i]);
        i = datos.length;
        return;
      }
      if (datos[i].tiempo_vida_util_del_producto === "") {
        Swal.fire("Error", `El tiempo de vida util : ${datos[i].key} está vacio`, "error");
        edit(datos[i]);
        i = datos.length;
        return;
      }
      if (datos[i].registronotificacion_sanitaria === "") {
        Swal.fire(
          "Error",
          `El registro notificacion sanitaria : ${datos[i].key} está vacio`,
          "error"
        );
        edit(datos[i]);
        i = datos.length;
        return;
      }
      if (datos[i].fecha_vencimiento === "") {
        Swal.fire("Error", `La fecha_vencimiento : ${datos[i].key} está vacio`, "error");
        edit(datos[i]);
        i = datos.length;
        return;
      }
      if (datos[i].graba_ice === "") {
        Swal.fire("Error", `El graba_ice : ${datos[i].key} está vacio`, "error");
        edit(datos[i]);
        i = datos.length;
        return;
      }
      if (datos[i].graba_iva === "") {
        Swal.fire("Error", `El graba IVA: ${datos[i].key} está vacio`, "error");
        edit(datos[i]);
        i = datos.length;
        return;
      }
      if (datos[i].impuesto_verde === "") {
        Swal.fire("Error", `El impuesto verde : ${datos[i].key} está vacio`, "error");
        edit(datos[i]);
        i = datos.length;
        return;
      }

      if (datos[i].pvf === "") {
        Swal.fire("Error", `El PVF : ${datos[i].key} está vacio`, "error");
        edit(datos[i]);
        i = datos.length;
        return;
      }
      if (datos[i].pvp === "") {
        Swal.fire("Error", `El PVP : ${datos[i].key} está vacio`, "error");
        edit(datos[i]);
        i = datos.length;
        return;
      }
      if (datos[i].alto_caja === "") {
        Swal.fire("Error", `El alto_caja : ${datos[i].key} está vacio`, "error");
        edit(datos[i]);
        i = datos.length;
        return;
      }
      if (datos[i].ancho_caja === "") {
        Swal.fire("Error", `El ancho_caja : ${datos[i].key} está vacio`, "error");
        edit(datos[i]);
        i = datos.length;
        return;
      }
      if (datos[i].largo_caja === "") {
        Swal.fire("Error", `El largo_caja  : ${datos[i].key} está vacio`, "error");
        edit(datos[i]);
        i = datos.length;
        return;
      }
      if (datos[i].peso_bruto_caja === "") {
        Swal.fire("Error", `El peso_bruto_caja : ${datos[i].key} está vacio`, "error");
        edit(datos[i]);
        i = datos.length;
        return;
      }
      if (datos[i].alto_bulto === "") {
        Swal.fire("Error", `El alto_bulto : ${datos[i].key} está vacio`, "error");
        edit(datos[i]);
        i = datos.length;
        return;
      }
      if (datos[i].ancho_bulto === "") {
        Swal.fire("Error", `El ancho_bulto : ${datos[i].key} está vacio`, "error");
        edit(datos[i]);
        i = datos.length;
        return;
      }
      if (datos[i].largo_bulto === "") {
        Swal.fire("Error", `El largo_bulto : ${datos[i].key} está vacio`, "error");
        edit(datos[i]);
        i = datos.length;
        return;
      }

      if (datos[i].tipo_de_producto.toLowerCase() === "psicotropicos" && datos[i].calificacion.trim() === "") {
        Swal.fire("Error", `Cuando es un producto psicotropico debe especificar el código de calificación del laboratorio,Producto #${datos[i].key} corregir en el excel.`, "error");
        // edit(datos[i]);
        // i = datos.length;
        return;
      }
    }
    let conteo = [];
    for (let elemento in files) {
      conteo.push(elemento);
    }
    let c = datos.length * 3;
    let d = conteo.length;

    for (const a of datos) {
      if (
        typeof files[`img${a.key}`] === "undefined" ||
        files[`img${a.key}`]?.fileList.length < 2 ||
        c < d
      ) {
        return Swal.fire(
          "Error",
          "Las 2 imagenes y el documento del registro sanitario son obligatorias por cada producto",
          "warning"
        );
      }
      if (files[`pdf${a.key}`].fileList[0].originFileObj.size / 1024 / 1024 > 4) {
        return Swal.fire("Error", `El producto ${a.nombre_del_producto} en su archivo pdf excede el tamaño permitido (4MB)!`, "warning");
      }
      if (files[`img${a.key}`].fileList[0].originFileObj.size / 1024 / 1024 > 4) {
        return Swal.fire("Error", `El producto ${a.nombre_del_producto} en su imagen 1 excede el tamaño permitido (4MB)!`, "warning");
      }
      if (files[`img${a.key}`].fileList[1].originFileObj.size / 1024 / 1024 > 4) {
        return Swal.fire("Error", `El producto ${a.nombre_del_producto} en su imagen 2 excede el tamaño permitido (4MB)!`, "warning");
      }
    }
    let allKeys = [];
    let llaves = [];
    for (const a of datos) {
      let archivos = [];
      archivos.push(files[`img${a.key}`].fileList[0]);
      archivos.push(files[`img${a.key}`].fileList[1]);
      archivos.push(files[`pdf${a.key}`].fileList[0]);
      setLoadings(true);
      llaves = await helperLlaves(archivos);

      formData.append("arr[]", JSON.stringify(a));
      formData.append("img_0", llaves[0]);
      formData.append("img_1", llaves[1]);
      formData.append("pdf", llaves[2]);
      allKeys.push(llaves[0]);
      allKeys.push(llaves[1]);
      allKeys.push(llaves[2]);
    }
    if (llaves.ok) {
      setLoadings(false);
      return Swal.fire("Error", "Ocurrio un error", "error");
    }
    formData.append("idUsuario", idUsuario);
    dispatch(
      startAddMasiveProduct(
        formData,
        form.getFieldValue(),
        datos,
        edit,
        setFile,
        setLoadings,
        remove, allKeys
      )
    );
  };

  function onChange(pagination, filters, sorter, extra) {
    //console.log(form1.getFieldValue());
  }

  return (
    <Form component={false} form={form}>
      <Table
        pagination={false}
        onChange={onChange}
        scroll={{
          x: true,
        }}
        style={{
          margin: "5px",
        }}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        size='small'
        dataSource={datos}
        columns={mergedColumns}
        rowClassName='editable-row'
      />

      <div className='butonDrag'>
        <Button
          type='cancel'
          style={{ width: "150px", marginLeft: "10px" }}
          disabled={loadings}
          icon={
            <MdCancel style={{ position: "absolute", right: "10px", color: "#6a2831" }} size={22} />
          }
          onClick={() => remove()}
        >
          Cancelar
        </Button>

        <Button
          type='primary'
          htmlType='submit'
          loading={loadings}
          onClick={onCreate}
          icon={<MdSave style={{ position: "absolute", right: "10px" }} size={22} />}
          style={{ width: "150px", marginLeft: "10px" }}
        >
          Guardar
        </Button>
      </div>
    </Form >
  );
};

export default AfterFile;
