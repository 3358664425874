import { useForm } from "antd/lib/form/Form";
import { Tag } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useCallback, useState } from "react";

import { useEffect } from "react";
import { fetchHelpToken } from "../../helper/fetch";

export const useViewProductsCompleted = () => {
  const [modal, setModal] = useState(false);
  const [cod_producto, setCod_producto] = useState(0);
  const [estado, setEstado] = useState();
  const [loader, setLoader] = useState(false);
  const [laboratorios, setlaboratorios] = useState([]);
  const [selectLab, setSelectLab] = useState();
  const [productos, setProductos] = useState(null);

  const [form] = useForm();

  const columns = [
    {
      title: "Producto",
      dataIndex: "producto",
      key: "producto",
      width: "1fr",
    },
    {
      title: "Laboratorio",
      dataIndex: "lab",
      key: "lab",
      width: "30%",
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      width: "10%",
      align: "center",
      render: (estado) => {
        return estado ? <Tag color='success'>Activo</Tag> : <Tag color='error'>Inactivo</Tag>;
      },
    },

    {
      title: "Acción",
      dataIndex: "accion",
      key: "accion",
      align: "center",
      width: "10%",
      render: (_, record) => {
        return <EyeOutlined style={{ cursor: "pointer" }} onClick={() => handleModal(record)} />;
      },
    },
  ];

  const handleSelectLab = (value) => {
    loadData(value);
    setSelectLab(value);
  };

  const handleModal = (record) => {
    setEstado(record.estado);
    setCod_producto(record.key);
    setModal(true);
  };

  //const handleLoadProduct = (e) => {
  //if (e.target.value.trim().length > 0 && e.keyCode === 13) {
  //dispatch(resetProducts());
  // dispatch(startLoadProduct(e.target.value, setLoader));
  //}
  // else {
  //   dispatch(startLoadProduct(e.target.value));
  // }
  //};

  const loadData = useCallback(async (id = 0) => {
    setProductos(null);
    const res = await fetchHelpToken("interna/portal-laboratorios", "GET");
    const data = await res.json();
    if (data.info.msg) {
      setlaboratorios(data.info.data);
      const peticion = await fetchHelpToken("interna/portal-pro-lab", { id }, "POST");
      const respuesta = await peticion.json();
      if (respuesta.info.data.length > 0) setProductos(respuesta.info.data);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return {
    productos,
    columns,
    modal,
    setModal,
    form,
    cod_producto,
    loader,
    estado,
    setLoader,
    laboratorios,
    handleSelectLab,
    setProductos,
    selectLab,
    loadData,
  };
};
