
import { Navigate, Outlet } from "react-router-dom";

const PublicRoutes = ({ idUsuario }) => {
  let lastVisitedUrl = localStorage.getItem("lastVisitedUrl") || "";

  let count = lastVisitedUrl.split('/').length - 1;

  if (count === 1) {
    lastVisitedUrl = "/app" + lastVisitedUrl;
  }

  return idUsuario >= 0 ? <Navigate to={lastVisitedUrl} /> : <Outlet />;

};

export default PublicRoutes;
