import { Form, Input, Button } from "antd";
import { useDispatch } from "react-redux";
import { startNewPass } from "../../store/slices/auth/thunk";
import { MdLock, MdSend } from "react-icons/md";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useState } from "react";

const NewPass = ({ title, handleCancel }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    dispatch(startNewPass(values, handleCancel, form, setLoading));
  };

  return (
    <div className='App2'>
      <div className='form2'>
        <Form
          form={form}
          name='normal_login'
          className='login-form'
          layout='vertical'
          style={{
            width: "100%",
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label={title}
            name='contraseña'
            rules={[
              {
                required: true,
                message: "Ingrese la nueva contraseña!",
              },
            ]}
          >
            <Input.Password
              placeholder='Ingrese la nueva contraseña'
              autoComplete='off'
              type='password'
              prefix={<MdLock className='site-form-item-icon' size={24} />}
              size='large'
              iconRender={(visible) =>
                visible ? <EyeTwoTone size={24} /> : <EyeInvisibleOutlined size={24} />
              }
            />
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button'
              icon={<MdSend style={{ position: "absolute", right: "10px" }} size={22} />}
              style={{ width: "100%" }}
              loading={loading}
            >
              Actualizar
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default NewPass;
