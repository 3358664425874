import { configureStore } from "@reduxjs/toolkit";
import { authCredential } from "./slices/auth/login";
import { productosReload } from "./slices/productos/productos";
import { tableExcel } from "./slices/proveedor/tableExcel";
import { uiModal } from "./slices/ui/uiModal";

export const store = configureStore({
  reducer: {
    ui: uiModal.reducer,
    table: tableExcel.reducer,
    auth: authCredential.reducer,
    storeReload: productosReload.reducer,
  },
});
