const baseUrl = process.env.REACT_APP_API_URL;

export const fetchHelp = async (endpoint, data, method = "GET") => {
  const url = `${baseUrl}/${endpoint}`;

  if (method === "GET") {
    return fetch(url);
  } else {
    return fetch(url, {
      method,
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
};

export const fetchHelpToken = async (endpoint, data, method = "GET") => {
  const url = `${baseUrl}/${endpoint}`;
  const token = localStorage.getItem("x-token");
  if (method === "GET") {
    return fetch(url, {
      headers: {
        "Content-type": "application/json",
        "x-token": token,
      },
    });
  } else {
    return fetch(url, {
      method,
      headers: {
        "Content-type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify(data),
    });
  }
};

export const fetchForm = async (endpoint, data, method = "GET") => {
  const url = `${baseUrl}/${endpoint}`;
  const token = localStorage.getItem("x-token");

  if (method === "GET") {
    return fetch(url);
  } else {
    return fetch(url, {
      //mode: 'no-cors',
      method,
      headers: {
        "x-token": token,
      },
      body: data,
    });
  }
};
