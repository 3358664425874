import { Avatar, List } from "antd";
import React, { useState, useCallback } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchHelpToken } from "../../helper/fetch";

const ListarNotificacion = ({ onClose }) => {
  const [list, setList] = useState([]);
  const { reload } = useSelector((state) => state.storeReload);

  const { credential } = useSelector((state) => state.auth);
  const { cod_luc } = credential;

  const getList = useCallback(async () => {
    const res = await fetchHelpToken("productos/notificacion", { cod_luc }, "POST");
    const data = await res.json();
    if (data.ok) {
      setList(data.list);
    }
  }, [cod_luc, setList]);

  useEffect(() => {
    const getListAndLog = async () => {
      await getList();
    };
    getListAndLog();
  }, [reload, getList]);

  return (
    <List
      itemLayout='horizontal'
      dataSource={list}
      locale={{ emptyText: "Sin productos pendientes" }}
      renderItem={(item) => {
        let img = item.imagenes?.split(",");
        return (
          <>
            {img && (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar src={`https://infofsg.com/allku/archivo/${img[0]}`} />}
                  title={
                    <Link to='productos' onClick={onClose}>
                      {item.nombres.toUpperCase()}
                    </Link>
                  }
                  description={`El producto ${item.nombres.toUpperCase()}, es de tipo ${
                    item.tipo_producto
                  } en su forma de aplicación ${item.forma_aplicacion}.`}
                />
              </List.Item>
            )}
          </>
        );
      }}
    />
  );
};

export default ListarNotificacion;
