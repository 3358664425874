import axios from "axios";
import Swal from "sweetalert2";

import { fetchForm, fetchHelpToken } from "../../../helper/fetch";
import { loadProducts, reloader } from "./productos";
import moment from "moment";

const baseUrlAllku = process.env.REACT_APP_ALLKU_URL;

export const startUpdateProduct = (datos, handleCancel3, setUpdate, setLoadings) => {
  return async (dispatch, getState) => {
    setLoadings(true);
    const res = await fetchHelpToken("productos/actualizar", datos, "POST");
    const data = await res.json();

    if (data.ok) {
      setUpdate((update) => !update);
      Swal.fire("Ok", data.msg, "success");
      handleCancel3();
    }
    setLoadings(false);
  };
};

export const startAddProduct = (data, setLoadings, form, archivos) => {
  return async (dispatch, getState) => {
    const a = getState().auth.credential.idUsuario;

    setLoadings(true);
    const res = await fetchForm("productos/add", data, "POST");
    const datos = await res.json();
    if (datos.ok) {
      setLoadings(false);
      form.resetFields();
      localStorage.removeItem(a);
      return Swal.fire("Ok", datos.msg, "success");
    }
    await axios.post(`https://infofsg.com/allku/proveedores/eliminar`, {
      files: JSON.stringify(archivos),
    });

    Swal.fire("Error", "Ocurrio un error, por favor intente nuevamente", "error");
    setLoadings(false);
  };
};

export const startAddMasiveProduct = (form, file, datos, edit, setFile, setLoadings, remove, allKeys) => {
  return async (dispatch, getState) => {
    setLoadings(true);
    let mensaje = "";
    const res = await fetchForm("productos/masive", form, "POST");
    const json = await res.json();
    if (!json.ok) {
      setLoadings(false);

      json.error.map((el) => (mensaje += "producto#" + el.i + ": " + el.msg + "\n"));
      Swal.fire("Error", mensaje, "error");
      edit(datos[json.error[0].i - 1]);

      await axios.post(`https://infofsg.com/allku/proveedores/eliminar`, {
        files: JSON.stringify(allKeys),
      });
    } else {
      Swal.fire("Ok", json.msg, "success");
      setFile([]);
      remove();
    }
    setLoadings(false);
  };
};

export const reloadCount = (value) => {
  return (dispatch) => {
    dispatch(reloader(value));
  };
};

export const startLoadProduct = (descripcion, setLoader) => {
  return async (dispatch, getState) => {
    setLoader(true);
    const res = await fetchHelpToken("interna/portal-productos", { descripcion }, "POST");
    const data = await res.json();
    if (data.info.msg) {
      dispatch(loadProducts(data.info.data));
    }
    setLoader(false);
  };
};

// export const startLoadLaboratorios = (descripcion, setLoader) => {
//   return async (dispatch, getState) => {
//     // setLoader(true);
//     const res = await fetchHelpToken("interna/portal-laboratorios", "GET");
//     const data = await res.json();
//     console.log(data);
//     // if (data.info.msg) {
//     //   dispatch(loadProducts(data.info.data));
//     // }
//     // setLoader(false);
//   };
// };

async function fetchImageAsBlob(url) {
  const response = await fetch(url);
  const data = await response.blob();
  return new Blob([data], { type: "image/jpeg" });
}

async function fetchPdfAsBlob(url) {
  const response = await fetch(url);
  const blob = await response.blob();
  return blob;
}

export const LoadProductId = (cod_producto, form, setShowCalificacion, setFileList, setFileLlaves, setFileListPdf, setPreviewPdf) => {
  return async (dispatch, getState) => {
    const res = await fetchHelpToken("interna/datos-productos-id", { cod_producto }, "POST");
    const data = await res.json();
    setShowCalificacion(parseInt(data.info.data.tipo_producto));

    data.info.data.fecha_de_lanzamiento_aa_mm_dd = moment(data.info.data.fecha_de_lanzamiento_aa_mm_dd);

    if (!data.info.data.fecha_de_lanzamiento_aa_mm_dd.isValid()) delete data.info.data.fecha_de_lanzamiento_aa_mm_dd;

    data.info.data.fecha_de_vencimiento = moment(data.info.data.fecha_de_vencimiento);
    if (!data.info.data.fecha_de_vencimiento.isValid()) delete data.info.data.fecha_de_vencimiento;

    const imagenList = [];
    const pdf = [];
    const llaves_archivos = JSON.parse(data.info.data.llaves_archivos);

    setFileLlaves(llaves_archivos);
    for (const llave of llaves_archivos) {
      if (!llave.toLowerCase().endsWith(".pdf")) {
        const url = `${baseUrlAllku}archivos/${llave}`;
        const blob = await fetchImageAsBlob(url);
        const file = new File([blob], llave, { type: blob.type });
        imagenList.push({
          uid: `image-${llave}`,
          name: llave,
          url,
          status: "done",
          response: { llave },
          originFileObj: file,
        });
      } else {
        const url = `${baseUrlAllku}archivos/${llave}`;

        const blob = await fetchPdfAsBlob(url);
        const file = new File([blob], llave, { type: blob.type });
        setPreviewPdf(url);
        pdf.push({
          uid: `pdf-${llave}`,
          name: llave,
          url,
          status: "done",
          response: { llave },
          originFileObj: file,
        });
      }
    }

    setFileList(imagenList);
    setFileListPdf(pdf);
    form.setFieldsValue(data.info.data);
    // if (data.info.msg) {
    //   dispatch(loadProducts(data.info.data));
    // }
  };
};

/*



*/
