import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { userModal } from "../../store/slices/ui/uiModal";
import EditUser from "./EditUser";

const EditAddUser = ({ editarPersona, setUpdateTable }) => {
  const dispatch = useDispatch();
  const { ui } = useSelector((state) => state);

  const handleCancel = () => {
    dispatch(userModal({ value: false }));
  };

  return (
    <>
      <Modal
        visible={ui.isOpen}
        title='Editar Usuario'
        onCancel={handleCancel}
        footer={null}
        style={{ position: "relative", top: "20px" }}
        transitionName=''
      >
        <EditUser
          editarPersona={editarPersona}
          handleCancel={handleCancel}
          setUpdateTable={setUpdateTable}
        />
      </Modal>
    </>
  );
};

export default EditAddUser;
