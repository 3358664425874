import { useDispatch, useSelector } from "react-redux";
import { startLoadProduct } from "../../store/slices/productos/thunk";
import { useForm } from "antd/lib/form/Form";
import { Tag } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useState } from "react";
import { resetProducts } from "../../store/slices/productos/productos";

export const useViewProducts = () => {
  const dispatch = useDispatch();
  const { productos } = useSelector((state) => state.storeReload);
  const [modal, setModal] = useState(false);
  const [cod_producto, setCod_producto] = useState(0);
  const [estado, setEstado] = useState();
  const [loader, setLoader] = useState(false);

  const [form] = useForm();

  const columns = [
    {
      title: "Producto",
      dataIndex: "producto",
      key: "producto",
      width: "1fr",
    },
    {
      title: "Laboratorio",
      dataIndex: "lab",
      key: "lab",
      width: "10%",
      align: "center",
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      width: "10%",
      align: "center",
      render: (estado) => {
        return estado ? <Tag color='success'>Activo</Tag> : <Tag color='error'>Inactivo</Tag>;
      },
    },
    {
      title: "Acción",
      dataIndex: "accion",
      key: "accion",
      align: "center",
      width: "10%",
      render: (_, record) => {
        return <EyeOutlined style={{ cursor: "pointer" }} onClick={() => handleModal(record)} />;
      },
    },
  ];

  const handleModal = (record) => {
    setEstado(record.estado);
    setCod_producto(record.key);
    setModal(true);
  };

  const handleLoadProduct = (value, _e, info) => {
    if (value.trim().length > 0) {
      dispatch(resetProducts());
      dispatch(startLoadProduct(value, setLoader));
    }
    // else {
    //   dispatch(startLoadProduct(e.target.value));
    // }
  };

  return {
    handleLoadProduct,
    productos,
    columns,
    modal,
    setModal,
    form,
    cod_producto,
    loader,
    estado,
    setLoader,
  };
};
