import React, { useCallback, useEffect, useState } from "react";
import { Table, Tag, Switch, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { userModal } from "./../../store/slices/ui/uiModal";
import { fetchHelpToken } from "../../helper/fetch";
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { AiFillEdit } from "react-icons/ai";
import EditAddUser from "./EditAddUser";
import Swal from "sweetalert2";
import Loader from "../ui/Loader";
import { useTableShearch } from "../../hooks/hooksproveedores/useTableShearch";
import { startResendEmail } from "../../store/slices/auth/thunk";
import { BiMailSend } from "react-icons/bi";

const Usuarios = () => {
  const [personas, setPersonas] = useState([]);
  const [editarPersona, setEditarPersona] = useState({});
  const [updateTable, setUpdateTable] = useState(false);
  const dispatch = useDispatch();
  const { getColumnSearchProps } = useTableShearch();
  const [loading, setLoading] = useState({});

  const { credential } = useSelector((state) => state.auth);
  const { idUsuario, fkIdPerfil, cod_luc } = credential;

  const handleModal = (record) => {
    if (fkIdPerfil > 3) {
      return Swal.fire("Advertencia", "Opcion no disponible!", "warning");
    }
    if (record.apellidos === null || record.cedula === null) {
      return Swal.fire("Error", "Solo puede editar los usuarios que hayan culminado el proceso de registro!", "warning");
    }

    setEditarPersona(record);
    dispatch(userModal({ value: true }));
  };

  const handleMail = (record) => {
    if (record.apellidos || record.cedula) {
      return Swal.fire("Error", "El enlace solo se puede reenviar a usuarios que aún no hayan completado el proceso de registro!", "warning");
    }
    if (record.idpersona) {
      record.idPersona = record.idpersona;
    }
    dispatch(startResendEmail(record, setLoading, loading));
  };

  const handleDelete = async (value, record) => {
    const { idPersona } = record;

    Swal.fire({
      title: `Está seguro de ${value ? "activar" : "desactivar"} el usuario ${record.usuario}?`,
      showDenyButton: true,
      confirmButtonText: "Si",
      denyButtonText: "No",
      confirmButtonColor: "#6a2831",
      denyButtonColor: "#b60000",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await fetchHelpToken(`personas/estado/${idPersona}`, { value }, "POST");
        const data = await res.json();
        if (data.ok) {
          personas.map(function (dato) {
            if (dato.idPersona === record.idPersona) {
              dato.estado = value;
            }
            return dato;
          });

          setPersonas([...personas]);
          Swal.fire("Cambio realizado!", "", "success");
        }
      } else if (result.isDenied) {
        Swal.fire("No se realizó ningun cambio", "", "info");
      }
    });
  };

  const getPersonas = useCallback(async () => {
    let datos = [];
    const res = await fetchHelpToken("personas", { idUsuario, fkIdPerfil, cod_luc }, "Post");
    const data = await res.json();

    if (data.ok) {
      if (fkIdPerfil === 3) {
        setPersonas(data.user);
      } else {
        data.user.map(({ ...item }) => {
          item.persona.key = item.persona.idPersona;
          item.persona.usuario = item.usuario;
          item.persona.estado = item.estado;

          return datos.push(item.persona);
        });
        setPersonas(datos);
      }
    }
  }, [cod_luc, fkIdPerfil, idUsuario]);

  useEffect(() => {
    getPersonas();
  }, [updateTable, getPersonas]);

  const columns = [
    {
      title: "Usuario",
      dataIndex: "usuario",
      key: "usuario",
      width: "10%",
      render: (text) => (
        <div
          style={{
            maxWidth: "100px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          title={text}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Nombres",
      dataIndex: "nombres",
      key: "nombres",
      width: "10%",
      render: (text) => (
        <div
          style={{
            maxWidth: "120px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          title={text}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Apellidos",
      dataIndex: "apellidos",
      key: "apellidos",
      width: "10%",
      ...getColumnSearchProps("apellidos"),
      render: (text) => (
        <div
          style={{
            maxWidth: "120px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          title={text}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Correo",
      dataIndex: "correo",
      key: "correo",
      width: "15%",
      ...getColumnSearchProps("correo"),
      render: (text) => (
        <div
          style={{
            maxWidth: "220px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          title={text}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Cedula",
      dataIndex: "cedula",
      key: "cedula",
      width: "9%",
      ...getColumnSearchProps("cedula"),
      render: (text) => (
        <div
          style={{
            maxWidth: "120px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          title={text}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Cargo",
      dataIndex: "cargo",
      key: "cargo",
      width: "9%",
      render: (text) => (
        <div
          style={{
            maxWidth: "110px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          title={text}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Telefono",
      dataIndex: "telefono",
      key: "telefono",
      width: "10%",
      render: (text) => (
        <div
          style={{
            maxWidth: "120px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          title={text}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Estado",
      key: "estado",
      dataIndex: "estado",
      width: "7%",
      fixed: "right",
      align: "center",
      render: (_, record) => (
        <div>
          {fkIdPerfil === 1 || fkIdPerfil === 3 ? (
            <Switch checked={record.estado} onClick={(value) => handleDelete(value, record)} />
          ) : (
            <div>
              {record.estado ? (
                <Tag icon={<CheckCircleOutlined />} color='success'>
                  Activo
                </Tag>
              ) : (
                <Tag icon={<ExclamationCircleOutlined />} color='warning'>
                  Inactivo
                </Tag>
              )}
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Editar",
      key: "editar",
      fixed: "right",
      width: "6%",
      align: "center",
      render: (_, record) => (
        <div style={{ width: "100%" }}>
          <p onClick={() => handleModal(record)} className='hover'>
            <AiFillEdit
              size={24}
              style={{
                backgroundColor: "#6a2831",
                borderRadius: "50px",
                color: "white",
                padding: "2px",
              }}
            />
          </p>
        </div>
      ),
    },
    ...(fkIdPerfil === 1 || fkIdPerfil === 3
      ? [
          {
            title: "Reenviar",
            key: "editar",
            fixed: "right",
            width: "8%",
            align: "center",
            render: (_, record) => (
              <div style={{ width: "100%" }}>
                <Button onClick={() => handleMail(record)} className='hover' type='text' loading={loading[record.key]}>
                  <BiMailSend
                    size={25}
                    style={{
                      backgroundColor: "#6a2831",
                      borderRadius: "50px",
                      color: "white",
                      padding: "2px",
                    }}
                  />
                </Button>
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      {personas.length > 0 ? (
        <Table
          title={() => (fkIdPerfil === 1 || fkIdPerfil === 3 ? "Lista de Usuarios" : "Administrar mi Usuario")}
          columns={columns}
          dataSource={personas}
          pagination={{ defaultPageSize: 15 }}
          scroll={{ x: 950, y: 350 }}
          style={{ height: "60vh", margin: "7px", position: "relative" }}
          size='small'
        />
      ) : (
        <Loader />
      )}

      <EditAddUser editarPersona={editarPersona} setUpdateTable={setUpdateTable} />
    </>
  );
};

export default Usuarios;
