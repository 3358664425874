import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Tabs,
  TreeSelect,
  Button,
  Pagination,
  Spin,
  Checkbox,
  Upload,
  Modal,
} from "antd";

import TextArea from "antd/lib/input/TextArea";
import locale from "antd/es/date-picker/locale/es_ES";

import { MdSave } from "react-icons/md";
import { useFormProduct } from "../../hooks/hooksproductos/useFormProduct";

import { UploadOutlined, EyeOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
const { Option } = Select;

const FormProduct = ({
  cod_producto,
  setModal,
  form1,
  estado,
  setLoader,
  setProductos,
  loadData,
  productos,
  tipo,
  tipoCompletado = 0,
}) => {
  const {
    layout,
    dateFormat,
    laboratorios,
    principiosActivos,
    clasificacion_medicamento,
    clasificacion_atc_1,
    clasificacion_atc_2,
    clasificacion_atc_3,
    clasificacion_atc_4,
    clasificacion_atc_5,
    presentaciones,
    medidas,
    condiciones,
    tipoMercado,
    formaAplicacion,
    grupoObjetivo,
    tipoVentas,
    tipoExhibicion,
    tipoProducto,
    form,
    showCalificacion,
    onChangeAtc1,
    onChangeAtc2,
    onChangeAtc3,
    onChangeAtc4,
    filterOption,
    handleSumit,
    loading,
    tabKey,
    onChangeTab,
    handleTipoProducto,
    spining,
    fileList,
    handleChange,
    handlePreview,
    previewVisible,
    setPreviewVisible,
    previewImage,
    beforeUpload,
    previewPdf,
    previewVisiblePdf,
    setPreviewVisiblePdf,
    fileListPdf,
    handleChangePdf,
    categorias,
    handleCreate,
    fkIdPerfil,
  } = useFormProduct(cod_producto, setModal, setProductos, loadData, form1, productos, tipo, tipoCompletado);
  // 0= pvf ,  1=pvp

  return (
    <>
      {spining && (
        <Spin tip='Espere, por favor!' size='large' style={{ display: "block", margin: "auto", textAlign: "center" }}></Spin>
      )}

      {!spining && (
        <>
          <Pagination
            onChange={onChangeTab}
            pageSize={1}
            total={9}
            style={{ textAlign: "center", marginBottom: "10px" }}
            current={parseInt(tabKey)}
          />

          <Form layout={"vertical"} {...layout} form={form}>
            <Tabs activeKey={tabKey.toString()} onChange={onChangeTab} size='small' type='card' animated={false}>
              <TabPane tab='Desc. General' key={1} forceRender={true}>
                <Row wrap={true}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      name='laboratorio_fabricante'
                      label='Laboratorio Fabricante'
                      rules={[
                        {
                          required: true,
                          message: "Laboratorio es obligatorio!",
                        },
                      ]}
                    >
                      <TreeSelect
                        showSearch
                        style={{
                          width: "100%",
                        }}
                        treeNodeFilterProp='title'
                        dropdownStyle={{
                          maxHeight: 400,
                          overflow: "auto",
                        }}
                        placeholder='Seleccione un Laboratorio'
                        allowClear
                        treeData={laboratorios}
                      />
                    </Form.Item>
                    <Row wrap={true}>
                      <Col xs={24} lg={20}>
                        <Form.Item
                          label='Nombre del producto'
                          name='nombre_del_producto'
                          rules={[
                            {
                              required: true,
                              validator: (_, value) =>
                                value && value.trim().length > 0
                                  ? Promise.resolve()
                                  : Promise.reject(new Error("Nombre del producto es obligatorio!")),
                            },
                          ]}
                        >
                          <Input placeholder='Paracetamol' />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={4}>
                        <Form.Item name='es_activo' valuePropName='checked' initialValue={estado}>
                          <Checkbox>Activo</Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      name='principio_activo'
                      rules={[
                        {
                          required: tipo === 0,
                          message: "Principio activo es obligatorio !",
                        },
                      ]}
                      label='Principio Activo'
                    >
                      <Select
                        showSearch
                        placeholder='Seleccione un principio activo'
                        notFoundContent='No disponible'
                        allowClear
                        filterOption={filterOption}
                        options={principiosActivos}
                      ></Select>
                    </Form.Item>
                    <Form.Item
                      style={{ width: "100%" }}
                      label='Política de Devolución por caducidad'
                      name='politica_devolucion_caducidad'
                    >
                      <InputNumber addonAfter='meses' min={0} style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={12}>
                    <Row>
                      <Col xs={12}>
                        <Form.Item
                          name='fracciones'
                          label='Fracción'
                          rules={[
                            {
                              required: true,
                              message: "Fracciones es obligatorio",
                            },
                          ]}
                        >
                          <InputNumber placeholder='# de fracción' style={{ width: "100%" }} min={1} />
                        </Form.Item>
                      </Col>
                      <Col xs={12}>
                        <Form.Item
                          name='ubicacion'
                          label='Ubicación'
                          rules={[
                            {
                              required: true,
                              message: "Ubicación es obligatorio!",
                            },
                          ]}
                        >
                          <Select
                            allowClear={true}
                            style={{
                              width: "96%",
                            }}
                          >
                            <Option value='PISO1'>PISO1</Option>
                            <Option value='PISO2'>PISO2</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={0}>
                      <Col span={showCalificacion === 2 ? 12 : 24}>
                        <Form.Item
                          name='tipo_producto'
                          label='Tipo de producto'
                          rules={[
                            {
                              required: true,
                              message: "Tipo de producto es obligatorio!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder='Seleccione un tipo de producto'
                            notFoundContent='No disponible'
                            allowClear
                            onChange={handleTipoProducto}
                            filterOption={filterOption}
                            options={tipoProducto}
                          ></Select>
                        </Form.Item>
                      </Col>
                      {showCalificacion === 2 && (
                        <Col span={12}>
                          <Form.Item
                            name='calificacion'
                            label='Calificación'
                            style={{ width: "95%" }}
                            rules={[
                              {
                                required: true,
                                validator: (_, value) =>
                                  value && value.trim().length > 0
                                    ? Promise.resolve()
                                    : Promise.reject(new Error("Calificación es obligatoria, psicotrópico!")),
                              },
                            ]}
                          >
                            <Input placeholder='Escriba el codigo autor. por el Arcsa' />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Form.Item
                          name='clasificacion_del_medicamento'
                          label='Clasificación del medicamento'
                          rules={[
                            {
                              required: tipo === 0,
                              message: "Clasificación del medicamento es obligatorio!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder='Seleccione una clasificación'
                            notFoundContent='No disponible'
                            allowClear
                            filterOption={filterOption}
                            options={clasificacion_medicamento}
                          ></Select>
                        </Form.Item>
                      </Col>
                      <Col xs={12}>
                        <Form.Item
                          name='categoria_adicional'
                          label='Categoría Adicional'
                          rules={[
                            {
                              required: true,
                              message: "Categoría Adicional es obligatorio!",
                            },
                          ]}
                        >
                          <Select
                            style={{ width: "96%" }}
                            showSearch
                            placeholder='Seleccione una categoria adicional'
                            notFoundContent='No disponible'
                            allowClear
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                          >
                            {categorias?.map((el, index) => (
                              <Option key={index} value={el.siglas}>
                                {el.descripcion}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Form.Item
                          name='es_envase_primario'
                          label='Envase Primario'
                          rules={[
                            {
                              required: tipo === 0 && true,
                              message: "Envase Primario es obligatorio!",
                            },
                          ]}
                        >
                          <Select placeholder='Seleccione una opcion' allowClear>
                            <Option value={1}> Si</Option>
                            <Option value={0}> No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={12}>
                        <Form.Item
                          name='es_envase_secundario'
                          label='Envase Secundario'
                          rules={[
                            {
                              required: tipo === 0 && true,
                              message: "Envase Secundario es obligatorio!",
                            },
                          ]}
                        >
                          <Select placeholder='Seleccione una opcion' allowClear style={{ width: "96%" }}>
                            <Option value={1}> Si</Option>
                            <Option value={0}> No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab='Clasificaciones ATC' key={2} forceRender={true}>
                <Row wrap={true}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      name='clasificacion_atc_1'
                      label='Clasificación ATC 1'
                      rules={[
                        {
                          required: tipo === 0 && true,
                          message: "Clasificación atc 1 es obligatoria!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder='Seleccione una Clasificación Atc 1'
                        notFoundContent='No disponible'
                        allowClear
                        onChange={onChangeAtc1}
                        filterOption={filterOption}
                        options={clasificacion_atc_1}
                      ></Select>
                    </Form.Item>

                    <Form.Item
                      name='clasificacion_atc_2'
                      label='Clasificación ATC 2'
                      rules={[
                        {
                          required: tipo === 0 && true,
                          message: "Clasificación atc 2 es obligatoria!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder='Seleccione una Clasificación Atc 2'
                        notFoundContent='No disponible'
                        allowClear
                        onChange={onChangeAtc2}
                        filterOption={filterOption}
                        options={clasificacion_atc_2}
                      ></Select>
                    </Form.Item>

                    <Form.Item name='clasificacion_atc_3' label='Clasificación ATC 3'>
                      <Select
                        showSearch
                        placeholder='Seleccione una Clasificación Atc 3'
                        notFoundContent='No disponible'
                        allowClear
                        onChange={onChangeAtc3}
                        filterOption={filterOption}
                        options={clasificacion_atc_3}
                      ></Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={12}>
                    <Form.Item name='clasificacion_atc_4' label='Clasificación ATC 4'>
                      <Select
                        showSearch
                        placeholder='Seleccione una Clasificación Atc 4'
                        notFoundContent='No disponible'
                        allowClear
                        onChange={onChangeAtc4}
                        filterOption={filterOption}
                        options={clasificacion_atc_4}
                      ></Select>
                    </Form.Item>

                    <Form.Item name='clasificacion_atc_5' label='Clasificación ATC 5'>
                      <Select
                        showSearch
                        notFoundContent='No disponible'
                        placeholder='Seleccione una Clasificación Atc 5'
                        allowClear
                        filterOption={filterOption}
                        options={clasificacion_atc_5}
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab='Presentaciones' key={3} forceRender={true}>
                <Row wrap={true}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      name='presentacion'
                      label='Presentaciones'
                      rules={[
                        {
                          required: true,
                          message: "Presentación es obligatorio!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder='Seleccione una opcion'
                        notFoundContent='No disponible'
                        allowClear
                        filterOption={filterOption}
                        options={presentaciones}
                      ></Select>
                    </Form.Item>

                    <Form.Item
                      name='medida'
                      label='Dosificación'
                      rules={[
                        {
                          required: true,
                          message: "Dosificación del medicamento es obligatorio!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder='Seleccione una opcion'
                        notFoundContent='No disponible'
                        allowClear
                        filterOption={filterOption}
                        options={medidas}
                      ></Select>
                    </Form.Item>

                    <Form.Item
                      name='ventas_por_fraccion'
                      label='Ventas por fracción'
                      rules={[
                        {
                          required: tipo === 0,
                          message: "Ventas por fracción es obligatorio!",
                        },
                      ]}
                    >
                      <Select placeholder='Seleccione una opcion' allowClear>
                        <Option value={1}> Si</Option>
                        <Option value={0}> No</Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name='codigo_barra_unidad_ean13'
                      label='Código de barra por unidad EAN13'
                      rules={[
                        {
                          required: true,
                          validator: (_, value) =>
                            value && value.trim().length > 0
                              ? Promise.resolve()
                              : Promise.reject(new Error("Código de barra por unidad EAN13 es obligatorio!!")),
                        },
                      ]}
                    >
                      <Input placeholder='8002660031533' />
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={12}>
                    <Form.Item
                      name='condiciones_almacenamiento'
                      label='Condiciones de almacenamiento'
                      rules={[
                        {
                          required: tipo === 0 && true,
                          message: "Condición de almacenamiento es obligatorio!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder='Seleccione una opcion'
                        notFoundContent='No disponible'
                        allowClear
                        filterOption={filterOption}
                        options={condiciones}
                      ></Select>
                    </Form.Item>

                    <Form.Item
                      name='master_pack_de_producto'
                      label='Master pack de Productos'
                      rules={[
                        {
                          required: tipo === 0,
                          message: "Master Pack es obligatorio!",
                        },
                      ]}
                    >
                      <InputNumber min={0} style={{ width: "100%" }} placeholder={"6"} />
                    </Form.Item>

                    <Form.Item
                      name='codigo_barra_caja_ean14_ean128'
                      label='Código de barra por caja EAN14/EAN128'
                      rules={[
                        {
                          validator: (_, value) => {
                            if (tipo !== 0) return Promise.resolve();

                            if (value && value.trim().length > 0) return Promise.resolve();

                            return Promise.reject(new Error("Código de barra por caja EAN14/EAN128 es obligatorio!"));
                          },
                        },
                      ]}
                    >
                      <Input placeholder='18002660032322' />
                    </Form.Item>
                    <Form.Item
                      label='Temperatura Máx'
                      name='temperatura_maxima'
                      rules={[
                        {
                          required: true,
                          message: "Temperatura máxima es obligatorio!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{
                          width: "100%",
                        }}
                        formatter={(value) => `${value}°C`}
                        parser={(value) => value.replace("°C", "")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab='Sugerencias 1' key={4} forceRender={true}>
                <Row wrap={true}>
                  <Col xs={24} lg={12}>
                    <Form.Item label='Indicaciones' name='indicaciones'>
                      <TextArea
                        showCount
                        maxLength={2250}
                        style={{
                          height: 70,
                        }}
                      />
                    </Form.Item>

                    <Form.Item label='Posología' name='posologia'>
                      <TextArea
                        showCount
                        maxLength={2250}
                        style={{
                          height: 70,
                        }}
                      />
                    </Form.Item>

                    <Form.Item label='Precauciones de uso' name='precauciones_de_uso'>
                      <TextArea
                        showCount
                        maxLength={2250}
                        style={{
                          height: 70,
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={12}>
                    <Row>
                      <Col xs={12}>
                        <Form.Item label='Antidoto' name='antidoto'>
                          <TextArea
                            showCount
                            maxLength={2250}
                            style={{
                              height: 70,
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12}>
                        <Form.Item label='Efectos Secundarios' name='efectos_secundarios'>
                          <TextArea
                            showCount
                            maxLength={2250}
                            style={{
                              height: 70,
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item label='Patologia a la que aplica' name='patologia_a_la_que_aplica'>
                      <TextArea
                        showCount
                        maxLength={2250}
                        style={{
                          height: 70,
                        }}
                      />
                    </Form.Item>

                    <Form.Item label='Contraindicaciones' name='contraindicaciones'>
                      <TextArea
                        showCount
                        maxLength={2250}
                        style={{
                          height: 70,
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>

              <TabPane tab='Sugerencias 2' key={5} forceRender={true}>
                <Row wrap={true} gutter={[20, 30]}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      name='tipo_de_mercado'
                      label='Tipo de Mercado'
                      rules={[
                        {
                          required: tipo === 0,
                          message: "Tipo de mercado es obligatorio!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder='Seleccione una opcion'
                        notFoundContent='No disponible'
                        allowClear
                        filterOption={filterOption}
                        options={tipoMercado}
                      ></Select>
                    </Form.Item>

                    <Form.Item
                      name='forma_de_aplicacion'
                      label='Forma de aplicación'
                      rules={[
                        {
                          required: tipo === 0,
                          message: "Forma de aplicación es obligatorio!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder='Seleccione una opcion'
                        notFoundContent='No disponible'
                        allowClear
                        filterOption={filterOption}
                        options={formaAplicacion}
                      ></Select>
                    </Form.Item>

                    <Form.Item
                      name='grupo_objetivo'
                      label='Grupo Objetivo'
                      rules={[
                        {
                          required: tipo === 0,
                          message: "Grupo objetivo es obligatorio!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder='Seleccione una opcion'
                        notFoundContent='No disponible'
                        allowClear
                        filterOption={filterOption}
                        options={grupoObjetivo}
                      ></Select>
                    </Form.Item>

                    <Form.Item
                      name='tipo_venta'
                      label='Tipo de Ventas'
                      rules={[
                        {
                          required: tipo === 0,
                          message: "Tipo de venta es obligatorio!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder='Seleccione una opcion'
                        notFoundContent='No disponible'
                        allowClear
                        filterOption={filterOption}
                        options={tipoVentas}
                      ></Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={12}>
                    <Form.Item
                      name='fecha_de_lanzamiento_aa_mm_dd'
                      label='Fecha de lanzamiento'
                      rules={[
                        {
                          required: tipo === 0,
                          validator: (_, value) => {
                            if (tipo !== 0) return Promise.resolve();

                            if (value) return Promise.resolve();

                            return Promise.reject(new Error("Fecha de lanzamiento es obligatoria!"));
                          },
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        placeholder='Seleccione una fecha'
                        format={dateFormat}
                        locale={locale}
                      />
                    </Form.Item>

                    <Form.Item
                      name='tipo_exhibicion'
                      label='Tipo de Exhibición'
                      rules={[
                        {
                          required: tipo === 0,
                          message: "Tipo de venta es obligatorio!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder='Seleccione una opcion'
                        notFoundContent='No disponible'
                        allowClear
                        filterOption={filterOption}
                        options={tipoExhibicion}
                      ></Select>
                    </Form.Item>

                    <Form.Item name='recomendaciones' label='Recomendaciones'>
                      <Input placeholder='Escriba alguna recomendación' />
                    </Form.Item>
                    <Form.Item name='productos_similares' label='Productos similares en el mercado'>
                      <Input placeholder='Escriba productos similares' />
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab='Registro Sanitario' key={6} forceRender={true}>
                <Row wrap={true}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      label='Registro/Notificación sanitaria'
                      name='registronotificacion_sanitaria'
                      rules={[
                        {
                          required: true,
                          message: "Notificación sanitaria es obligatorio!",
                        },
                      ]}
                    >
                      <Input placeholder='4678-MEE-0419' />
                    </Form.Item>

                    <Form.Item
                      name='fecha_de_vencimiento'
                      label='Fecha de vencimiento'
                      rules={[
                        {
                          required: tipo === 0,
                          validator: (_, value) => {
                            if (tipo !== 0) return Promise.resolve();
                            if (value) return Promise.resolve();
                            return Promise.reject(new Error("Fecha de vencimiento es obligatoria!"));
                          },
                        },
                      ]}
                    >
                      <DatePicker
                        format={dateFormat}
                        style={{ width: "100%" }}
                        placeholder='Seleccione una fecha'
                        locale={locale}
                      />
                    </Form.Item>

                    <Form.Item
                      name='tiempo_vida_util_del_producto'
                      label='Tiempo de vida util (meses)'
                      rules={[
                        {
                          required: tipo === 0,
                          message: "Tiempo de vida util (meses) es obligatorio!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `${value}meses`}
                        parser={(value) => value.replace("meses", "")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Row gutter={[10, 10]}>
                      <Col xs={18}>
                        <Form.Item
                          name='file_registro_sanitario'
                          label='Archivo del registro sanitario'
                          rules={[
                            {
                              required: previewPdf.length === 0 && tipo === 0 ? true : false,
                              message: "Archivo del registro sanitario es obligatorio!",
                            },
                          ]}
                        >
                          <Upload
                            accept='.pdf'
                            maxCount={1}
                            beforeUpload={beforeUpload}
                            fileList={fileListPdf}
                            onChange={handleChangePdf}
                          >
                            <Button icon={<UploadOutlined />}>PDF del registro sanitario</Button>
                          </Upload>
                        </Form.Item>
                        <Modal
                          visible={previewVisiblePdf}
                          footer={null}
                          onCancel={() => setPreviewVisiblePdf(false)}
                          transitionName=''
                        >
                          <iframe title='Visor' src={previewPdf} width='100%' height='400' type='application/pdf'></iframe>
                        </Modal>
                      </Col>
                      <Col xs={6}>
                        {previewPdf && <Button icon={<EyeOutlined />} onClick={() => setPreviewVisiblePdf(true)} />}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24}>
                        <Form.Item
                          label='Subir 2 imagenes del producto'
                          name='imagenes'
                          rules={[
                            {
                              required: tipo === 0,
                              validator: (_, value) => {
                                if (tipo !== 0) return Promise.resolve();
                                if (fileList.length === 2) return Promise.resolve();
                                return Promise.reject(new Error("Por favor sube dos imágenes del producto."));
                              },
                            },
                          ]}
                        >
                          <Upload
                            maxCount={2}
                            fileList={fileList}
                            onChange={handleChange}
                            onPreview={handlePreview}
                            listType='picture-card'
                            beforeUpload={() => false}
                          >
                            <Button icon={<UploadOutlined />}>Subir</Button>
                          </Upload>
                        </Form.Item>
                        <Modal
                          visible={previewVisible}
                          footer={null}
                          onCancel={() => setPreviewVisible(false)}
                          transitionName=''
                        >
                          <img alt='Producto' style={{ width: "100%" }} src={previewImage} />
                        </Modal>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab='Desc. Comercial' key={7} forceRender={true}>
                <Row wrap={true}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      label='Graba Iva :'
                      name='graba_iva'
                      rules={[
                        {
                          required: true,
                          message: "Graba Iva es obligatorio!",
                        },
                      ]}
                    >
                      <Select placeholder='Seleccione una opción' optionFilterProp='children' style={{ width: "100%" }}>
                        <Option value={true}>Si</Option>
                        <Option value={false}>No</Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label='Graba Ice'
                      name='graba_ice'
                      rules={[
                        {
                          required: true,
                          message: "Graba Ice es obligatorio!",
                        },
                      ]}
                    >
                      <Select placeholder='Seleccione una opción' optionFilterProp='children' style={{ width: "100%" }}>
                        <Option value={1}>Si</Option>
                        <Option value={0}>No</Option>
                      </Select>
                    </Form.Item>

                    {/* <Form.Item
                label='Impuesto Verde'
                name='impuesto_verde'
                rules={[
                  {
                    required: true,
                    message: "Impuesto verde es obligatorio!",
                  },
                ]}
              >
                <Select
                  placeholder='Seleccione una opción'
                  optionFilterProp='children'
                  style={{ width: "100%" }}
                >
                  <Option value={1}>Si</Option>
                  <Option value={0}>No</Option>
                </Select>
              </Form.Item> */}

                    {/* <Form.Item name='costo' label='Costo (sin Iva)'>
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item> */}
                  </Col>

                  <Col xs={24} lg={12}>
                    <Form.Item
                      name='pvf'
                      label='PVF (Sin Iva)'
                      rules={[
                        {
                          required: true,
                          message: "El PVF es obligatorio!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                    <Form.Item
                      name='pvp'
                      label='PVP (Con Iva)'
                      rules={[
                        {
                          required: true,
                          message: "El PVP es obligatorio!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>

                    {/* <Form.Item name='descuento_producto_nuevo' label='Descuento Producto Nuevo'>
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace("%", "")}
                />
              </Form.Item>

              <Form.Item name='descuento_1' label='Descuento 1'>
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace("%", "")}
                />
              </Form.Item>

              <Form.Item name='bonificacion' label='Bonificación'>
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace("%", "")}
                />
              </Form.Item> */}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab='Medidas y peso (Estuche o Caja)' key={8} forceRender={true}>
                <Row wrap={true}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      label='Profundidad(cm)'
                      name='profundidad_caja'
                      rules={[
                        {
                          required: tipo === 0,
                          message: "La caracteristica profundidad es obligatoria!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `${value}cm`}
                        parser={(value) => value.replace("cm", "")}
                      />
                    </Form.Item>

                    <Form.Item
                      label='Ancho (cm)'
                      name='ancho_caja'
                      rules={[
                        {
                          required: tipo === 0,
                          message: "La caracteristica ancho es obligatoria!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `${value}cm`}
                        parser={(value) => value.replace("cm", "")}
                      />
                    </Form.Item>

                    <Form.Item
                      label='Largo (cm)'
                      name='largo_caja'
                      rules={[
                        {
                          required: tipo === 0,
                          message: "La caracteristica Largo es obligatoria!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `${value}cm`}
                        parser={(value) => value.replace("cm", "")}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={12}>
                    <Form.Item
                      label='Peso bruto (gr)'
                      name='peso_bruto_caja'
                      rules={[
                        {
                          required: tipo === 0,
                          message: "La caracteristica Peso Bruto es obligatoria!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `${value}gr`}
                        parser={(value) => value.replace("gr", "")}
                      />
                    </Form.Item>

                    <Form.Item label='Peso Neto contenido (gr)' name='peso_neto_contenido_caja'>
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `${value}gr`}
                        parser={(value) => value.replace("gr", "")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab='Medidad y peso del producto (Bulto o Corruga)' key={9} forceRender={true}>
                <Row wrap={true}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      label='Profundidad (cm)'
                      name='profundidad_bulto'
                      rules={[
                        {
                          required: tipo === 0,
                          message: "La caracteristica Profundidad es obligatoria!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `${value}cm`}
                        parser={(value) => value.replace("cm", "")}
                      />
                    </Form.Item>

                    <Form.Item
                      label='Ancho (cm)'
                      name='ancho_bulto'
                      rules={[
                        {
                          required: tipo === 0,
                          message: "La caracteristica Ancho Bulto es obligatoria!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `${value}cm`}
                        parser={(value) => value.replace("cm", "")}
                      />
                    </Form.Item>

                    <Form.Item
                      label='Largo (cm)'
                      name='largo_bulto'
                      rules={[
                        {
                          required: tipo === 0,
                          message: "La caracteristica Largo es obligatoria!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `${value}cm`}
                        parser={(value) => value.replace("cm", "")}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={12}>
                    <Form.Item
                      label='Peso bruto presentación primaria ( Kg.)'
                      style={{ width: "100%" }}
                      name='peso_bruto_presentacion_primaria_bulto'
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `${value}kg`}
                        parser={(value) => value.replace("kg", "")}
                      />
                    </Form.Item>

                    <Form.Item label='Cantidad caja por Pallet' style={{ width: "100%" }} name='cantidad_caja_por_pallet'>
                      <InputNumber min={0} style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item
                      label='Medida del pallet que usa el proveedor'
                      style={{ width: "100%" }}
                      name='medida_del_pallet_que_usa_el_proveedor'
                    >
                      <Input placeholder='1 x 1.2' />
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
            {fkIdPerfil != 7 && (
              <div style={{ marginTop: "20px", display: "flex" }} className='butonDrag'>
                <Form.Item>
                  <Button
                    loading={loading}
                    type='primary'
                    icon={<MdSave style={{ position: "absolute", right: "10px" }} size={22} />}
                    style={{ width: "170px", marginRight: "10px" }}
                    htmlType='submit'
                    onClick={tipo === 0 ? handleSumit : handleCreate}
                  >
                    {tipo === 0 ? "Actualizar" : "Guardar"}
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form>
        </>
      )}
    </>
  );
};

export default FormProduct;
