import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  datos: [],
  header: [],
};

export const tableExcel = createSlice({
  name: "table",
  initialState,
  reducers: {
    loadTable: (state, action) => {
      state.datos = action.payload.value;
    },
    loadHeader: (state, action) => {
      state.header = action.payload.head;
    },
    updateTable: (state, action) => {
      state.datos = action.payload.update;
    },
  },
});

export const { loadTable, loadHeader, updateTable } = tableExcel.actions;
