import { Form, Modal, Select, Table } from "antd";
import React from "react";
import FormProduct from "./FormProduct";
import Card from "antd/lib/card/Card";
import { useViewProductsCompleted } from "../../hooks/hooksproductos/useViewProductsCompleted";

const ViewProductsCompleted = () => {
  const { form, laboratorios, handleSelectLab, productos, columns, setModal, modal, cod_producto, estado, setLoader, setProductos, loadData } =
    useViewProductsCompleted();

  return (
    <>
      <Card title='Completar Registro'>
        <Form form={form}>
          <Form.Item label='Seleccione un laboratorio' name='lab' initialValue={0}>
            <Select options={laboratorios} onChange={handleSelectLab} />
          </Form.Item>
        </Form>

        {/* {loader && <Spin tip='Espere, por favor!' size='large' style={{ display: "block", margin: "auto", textAlign: "center" }}></Spin>} */}

        <Table
          bordered
          pagination={false}
          loading={productos || !productos?.length > 0 ? false : true}
          dataSource={productos}
          columns={columns}
          size='small'
          scroll={{ x: 900, y: "55vh" }}
        />

        <Modal
          title='GESTIONAR PRODUCTO'
          visible={modal}
          onCancel={() => setModal(false)}
          footer={null}
          width={"1200px"}
          transitionName=''
          destroyOnClose='true'
        >
          <FormProduct
            cod_producto={cod_producto}
            setModal={setModal}
            form1={form}
            estado={estado}
            setLoader={setLoader}
            setProductos={setProductos}
            loadData={loadData}
            productos={productos}
            tipo={0}
          />
        </Modal>
      </Card>
    </>
  );
};

export default ViewProductsCompleted;
