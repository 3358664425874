import { Modal, Select, Form, Button, Input, Divider, Space, Tooltip, Spin } from "antd";
import React, { useState, useRef } from "react";
import { PlusCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { fetchHelpToken } from "../../helper/fetch";
import { MdAddCircle, MdCancel, MdSave } from "react-icons/md";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const tipos = [
  {
    value: 1,
    label: "Laboratorios",
  },
  {
    value: 0,
    label: "Proveedores",
  },
];

const AgregarUsuario = () => {
  const { Option } = Select;
  const [form] = Form.useForm();

  const { credential } = useSelector((state) => state.auth);
  const { cod_luc } = credential;

  const [lista, setLista] = useState(null);
  const [correos, setCorreos] = useState([]);
  const [spining, setSpining] = useState(false);
  const [tipo, setTipo] = useState("_______________");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [tipoN, setTipoN] = useState();

  const [name, setName] = useState("");
  const inputRef = useRef(null);

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const addItem = (e) => {
    e.preventDefault();

    if (name.trim().length <= 0) {
      return;
    }
    setCorreos([...correos, { Correo: name }]);

    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };
  const getPreveedores = async () => {
    const res = await fetchHelpToken("proveedores");
    const inf = await res.json();
    setLista(inf.data?.data);
  };

  const getLaboratorios = async () => {
    const res = await fetchHelpToken("laboratorios");
    const inf = await res.json();
    setLista(inf.data?.data);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const onChangeTipo = (e) => {
    setLista(null);
    form.setFieldsValue({ proveedor: "" });
    if (e === 1) {
      setTipoN(1);
      getLaboratorios();
      setTipo("Laboratorios");
    } else if (e === 0) {
      getPreveedores();
      setTipo("Proveedores");
      setTipoN(0);
    } else {
      setTipo("______________");
    }
  };

  const onChange = async (value) => {
    setCorreos([]);
    setSpining(true);
    form.setFieldsValue({
      correo: null,
    });
    /* eslint eqeqeq: 0 */
    if (value == undefined) {
      setSpining(false);
      return setShowEmail(false);
    }

    const res = await fetchHelpToken(`proveedores/${value}`, { tipoN }, "POST");
    const inf = await res.json();

    //inputRef.current?.focus();
    if (inf.correos.msg === "ok") {
      setCorreos(inf.correos.data);
      setShowEmail(false);
    } else {
      inputRef.current?.focus();
      setShowEmail(true);
    }
    setSpining(false);
  };

  const handleFinish = async (value) => {
    setLoadings(true);
    const resp = await fetchHelpToken(
      "usuarios/proveedor",
      {
        fkIdProveedor: value.proveedor,
        correo: value.correo,
        tipoN,
        cod_luc,
      },
      "POST"
    );
    const data = await resp.json();
    if (data.ok) {
      Swal.fire("Ok", data.msg, "success");
      handleCancel();
      form.resetFields();
    }
    setLoadings(false);
  };

  return (
    <>
      <div className='add' onClick={showModal}>
        <PlusCircleOutlined style={{ fontSize: "56px", color: "#6a2831" }} />
      </div>

      <Modal title='Agregar Proveedor' visible={isModalVisible} onCancel={handleCancel} footer={null} transitionName='' destroyOnClose={true}>
        <Form onFinish={handleFinish} form={form} layout='vertical'>
          <Form.Item name='tipo' label='Opciones Disponibles:' style={{ marginBottom: "0px" }}>
            <Select
              showSearch
              placeholder='Seleccione un tipo'
              notFoundContent='No disponible'
              allowClear
              options={tipos}
              onChange={onChangeTipo}
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            >
              {lista?.map((el, index) => (
                <Option key={index} value={el.Cod_Proveedor}>
                  {`${el.Nombre}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='proveedor' label={`Lista de ${tipo}`} style={{ marginBottom: "0px" }}>
            <Select
              showSearch
              placeholder='Seleccione una opcion'
              notFoundContent='No disponible'
              allowClear
              onChange={onChange}
              options={lista}
              optionFilterProp='label'
            >
              {/* {lista?.map((el, index) => (
                <Option key={index} value={el.Cod_Proveedor}>
                  {`${el.Nombre}`}
                </Option>
              ))} */}
            </Select>
          </Form.Item>
          <Tooltip color={"#6a2831"} title='Si no se encuentra el correo en la lista , por favor escriba uno nuevo y luego dar click en el botón mas'>
            <Form.Item
              name='correo'
              label='Lista de Correos:'
              rules={[
                {
                  type: "email",
                  message: "No es un correo valido!",
                },
                {
                  required: true,
                  message: "El correo es obligatorio!",
                },
              ]}
            >
              <Select
                onDropdownVisibleChange={() => {
                  if (correos.length === 0 && !spining) {
                    setTimeout(() => {
                      inputRef.current?.focus();
                    }, 500);
                  }
                }}
                notFoundContent={!spining ? "Sin correos registrados" : <Spin indicator={antIcon} />}
                placeholder='correo@gmail.com'
                allowClear
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    <Space
                      style={{
                        padding: "0 8px 4px",
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Input
                        placeholder='Escriba un correo adicional: '
                        ref={inputRef}
                        value={name}
                        onChange={onNameChange}
                        className='list_correos'
                      />

                      <Button type='text' style={{ color: "#6a2831" }} icon={<MdAddCircle size={22} />} onClick={addItem}></Button>
                    </Space>
                  </>
                )}
              >
                {correos.length > 0 &&
                  correos.map((el, index) => (
                    <Option key={index} value={el.Correo}>
                      {el.Correo}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Tooltip>
          <Form.Item className='butonDrag'>
            <Button
              icon={<MdCancel style={{ position: "absolute", right: "10px", color: "#6a2831" }} size={22} />}
              htmlType='reset'
              style={{ marginTop: "25px", marginRight: "5px", width: "150px" }}
              disabled={loadings}
            >
              Cancelar
            </Button>
            <Button
              type='primary'
              htmlType='submit'
              loading={loadings}
              style={{ marginTop: "25px", marginRight: "5px", width: "150px" }}
              icon={<MdSave style={{ position: "absolute", right: "10px" }} size={22} />}
            >
              {!loadings ? "Guardar" : "Enviando"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AgregarUsuario;
