import React from "react";
import { Form, Input, Button, Image } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { MdLock, MdPerson, MdSend } from "react-icons/md";
import logo from "./logo.svg";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { startLogin } from "../../store/slices/auth/thunk";
import Card from "antd/lib/card/Card";
import { useState } from "react";

const Login = () => {
  const dispatch = useDispatch();
  const [loadings, setLoadings] = useState(false);

  const onFinish = (values) => {
    dispatch(startLogin(values, setLoadings));
  };

  return (
    <div className='App'>
      <div className='container_card'>
        <div className='site-card-border-less-wrapper'>
          <Card
            bordered={false}
            style={{
              maxWidth: "500px",
              height: "400px",
            }}
            className='Card_Loggin'
          >
            <Form
              name='normal_login'
              className='login-form'
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <div className='login__logo'>
                <Image style={{ maxWidth: "470px" }} src={logo} preview={false} />
              </div>

              <h4 style={{ marginBottom: "25px", color: "#6a2831", textAlign: "center" }}>
                Portal Proveedores
              </h4>

              <Form.Item
                name='usuario'
                rules={[
                  {
                    required: true,
                    message: "Ingrese su usuario!",
                  },
                ]}
              >
                <Input
                  prefix={<MdPerson className='site-form-item-icon' size={24} />}
                  placeholder='Usuario'
                  autoComplete='off'
                  size='large'
                />
              </Form.Item>
              <Form.Item
                name='contraseña'
                rules={[
                  {
                    required: true,
                    message: "Escriba su contraseña!",
                  },
                ]}
              >
                <Input.Password
                  prefix={<MdLock className='site-form-item-icon' size={24} />}
                  type='password'
                  size='large'
                  placeholder='Contraseña'
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone size={24} /> : <EyeInvisibleOutlined size={24} />
                  }
                />
              </Form.Item>

              <Form.Item>
                <Button
                  loading={loadings}
                  type='primary'
                  htmlType='submit'
                  className='login-form-button'
                  color='red'
                  icon={<MdSend style={{ position: "absolute", right: "10px" }} size={24} />}
                >
                  INICIAR SESIÓN
                </Button>
              </Form.Item>

              <Form.Item>
                <Link
                  className='login-form-forgot'
                  to='/auth/recuperar'
                  style={{ fontSize: "15px" }}
                >
                  ¿Has olvidado la contraseña?
                </Link>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Login;
