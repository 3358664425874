import { useCallback } from "react";

import { useEffect } from "react";
import { fetchHelpToken } from "../../helper/fetch";
import { useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { useDispatch, useSelector } from "react-redux";
import { LoadProductId } from "../../store/slices/productos/thunk";
import { notification } from "antd";
import Swal from "sweetalert2";
import { helperLlaves } from "../../components/proveedor/helperLlaves";
import axios from "axios";

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 23,
  },
};

const dateFormat = "YYYY-MM-DD";

const baseUrlAllku = process.env.REACT_APP_ALLKU_URL;

export const useFormProduct = (cod_producto, setModal, setProductos, loadData, form1, productos, tipo, tipoCompletado) => {
  const dispatch = useDispatch();
  const [tabKey, setTabKey] = useState(1);

  const { credential } = useSelector((state) => state.auth);

  const { cod_luc, fkIdPerfil } = credential;

  const [laboratorios, setLaboratorios] = useState([]);
  const [principiosActivos, setPrincipiosActivos] = useState([]);
  const [clasificacion_medicamento, setClasificacion_medicamento] = useState([]);
  const [clasificacion_atc_1, setClasificacion_atc_1] = useState([]);
  const [clasificacion_atc_2, setClasificacion_atc_2] = useState([]);
  const [clasificacion_atc_3, setClasificacion_atc_3] = useState([]);
  const [clasificacion_atc_4, setClasificacion_atc_4] = useState([]);
  const [clasificacion_atc_5, setClasificacion_atc_5] = useState([]);
  const [presentaciones, setPresentaciones] = useState([]);
  const [medidas, setMedidas] = useState([]);
  const [condiciones, setCondiciones] = useState([]);
  const [tipoMercado, setTipoMercado] = useState([]);
  const [formaAplicacion, setFormaAplicacion] = useState([]);
  const [grupoObjetivo, setGrupoObjetivo] = useState([]);
  const [tipoVentas, setTipoVentas] = useState([]);
  const [tipoExhibicion, setTipoExhibicion] = useState([]);
  const [tipoProducto, setTipoProducto] = useState([]);
  const [showCalificacion, setShowCalificacion] = useState(0);
  const [loader, setLoader] = useState(false);

  let categorias = [
    { cod_categoria_adicional: 1, descripcion: "BIOLOGICO", siglas: "B" },
    { cod_categoria_adicional: 2, descripcion: "CONSUMO", siglas: "C" },
    { cod_categoria_adicional: 3, descripcion: "FOTOSENSIBLE", siglas: "F" },
    { cod_categoria_adicional: 4, descripcion: "REFRIGERACION", siglas: "R" },
    { cod_categoria_adicional: 5, descripcion: "MEDICINA", siglas: " " },
  ];

  const [loading, setLoading] = useState(false);

  const [spining, setSpining] = useState(false);

  const [fileList, setFileList] = useState([]);
  const [fileLlaves, setFileLlaves] = useState([]);
  const [fileListPdf, setFileListPdf] = useState([]);

  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);

  const [previewVisiblePdf, setPreviewVisiblePdf] = useState(false);
  const [previewPdf, setPreviewPdf] = useState("");

  const beforeUpload = (file) => {
    return new Promise((resolve, reject) => {
      const isPdf = file.type === "application/pdf";
      if (!isPdf) {
        Swal.fire("Error", "Solo se permiten archivos en formato PDF.", "warning");
        return false;
      }

      const isLt5M = file.size / 1024 / 1024 <= 4;
      if (!isLt5M) {
        Swal.fire("Error", "El archivo excede el tamaño permitido(4MB)", "warning");
        return false;
      }

      const url = URL.createObjectURL(file);

      setPreviewPdf(url);
      reject(false);
    });
  };

  const handleChange = (info) => {
    let fileList = [...info.fileList];

    fileList = fileList.slice(-2);
    setFileList(fileList);
  };

  const handleChangePdf = (info) => {
    let fileList = [...info.fileList];
    if (fileList.length === 0) {
      setPreviewPdf("");
      setFileListPdf([]);
      return;
    }
    // fileList = fileList.slice(-1);
    setFileListPdf(fileList);
  };

  // const handleChangePdf = (info) => {
  //   if (info.status === "removed") {
  //     setPreviewPdf("");
  //   } else {
  //     const url = URL.createObjectURL(info.originFileObj);
  //     setPreviewPdf(url);
  //   }
  //   setFileListPdf(info);
  // };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const handleTipoProducto = (e) => {
    if (parseInt(e) === 2) {
      setShowCalificacion(parseInt(e));
    } else {
      setShowCalificacion(parseInt(e));
    }
  };

  const tab1 = [
    "es_envase_primario",
    "principio_activo",
    "laboratorio_fabricante",
    "nombre_del_producto",
    "tipo_producto",
    "calificacion",
    "clasificacion_del_medicamento",
    "es_envase_secundario",
  ];

  const tab2 = ["clasificacion_atc_1", "clasificacion_atc_2"];

  const tab3 = [
    "presentacion",
    "medida",
    "ventas_por_fraccion",
    "codigo_barra_unidad_ean13",
    "condiciones_almacenamiento",
    "master_pack_de_producto",
    "codigo_barra_caja_ean14_ean128",
    "temperatura_maxima",
  ];

  const tab5 = [
    "tipo_de_mercado",
    "forma_de_aplicacion",
    "grupo_objetivo",
    "tipo_venta",
    "fecha_de_lanzamiento_aa_mm_dd",
    "tipo_exhibicion",
  ];

  const tab6 = [
    "registronotificacion_sanitaria",
    "fecha_de_vencimiento",
    "tiempo_vida_util_del_producto",
    "file_registro_sanitario",
    "imagenes",
  ];

  const tab7 = ["graba_iva", "graba_ice", "pvf", "pvp"];

  const tab8 = ["profundidad_caja", "ancho_caja", "largo_caja", "peso_bruto_caja"];

  const tab9 = ["profundidad_bulto", "ancho_bulto", "largo_bulto"];

  const [form] = useForm();

  const obtieneInfoInicial = useCallback(async () => {
    setSpining(true);
    const res = await fetchHelpToken("interna/datos-productos", { cod_producto }, "POST");
    const data = await res.json();

    if (data.info.msg) {
      let dataInicial = JSON.parse(data.info.data);
      setLaboratorios(dataInicial[0].laboratorios);
      setPrincipiosActivos(dataInicial[0].principios_activo);
      setClasificacion_medicamento(dataInicial[0].clasificacion_medicamento);
      setClasificacion_atc_1(dataInicial[0].clasificacion_atc_1);
      setClasificacion_atc_2(dataInicial[0].clasificacion_atc_2);
      setClasificacion_atc_3(dataInicial[0].clasificacion_atc_3);
      setClasificacion_atc_4(dataInicial[0].clasificacion_atc_4);
      setClasificacion_atc_5(dataInicial[0].clasificacion_atc_5);
      setPresentaciones(dataInicial[0].presentaciones);
      setMedidas(dataInicial[0].medidas);
      setCondiciones(dataInicial[0].condiciones_almacenamiento);
      setTipoMercado(dataInicial[0].tipo_mercado);
      setFormaAplicacion(dataInicial[0].forma_aplicacion);
      setGrupoObjetivo(dataInicial[0].grupo_objetivo);
      setTipoVentas(dataInicial[0].tipo_ventas);
      setTipoExhibicion(dataInicial[0].tipo_exhibicion);
      setTipoProducto(dataInicial[0].tipo_producto);
    }
    setSpining(false);
  }, [cod_producto]);

  const obtieneInfoProduct = useCallback(async () => {
    dispatch(
      LoadProductId(
        cod_producto,
        form,
        setShowCalificacion,
        setFileList,
        setFileLlaves,
        setFileListPdf,
        setPreviewPdf,
        beforeUpload,
        setProductos
      )
    );
  }, [form, cod_producto, dispatch, setProductos]);

  useEffect(() => {
    const getInfo = async () => {
      await obtieneInfoInicial();
      tipo === 0 && obtieneInfoProduct();
    };
    getInfo();
  }, [obtieneInfoInicial, obtieneInfoProduct, tipo]);

  const onChangeAtc1 = async (value) => {
    const res = await fetchHelpToken("interna/cambia-atc1", { value }, "POST");
    const data = await res.json();
    if (data.info.msg) {
      form.setFieldsValue({ clasificacion_atc_2: "" });
      form.setFieldsValue({ clasificacion_atc_3: "" });
      form.setFieldsValue({ clasificacion_atc_4: "" });
      form.setFieldsValue({ clasificacion_atc_5: "" });
      let dataAtc = JSON.parse(data.info.data);
      setClasificacion_atc_2(dataAtc[0].clasificacion_atc_2);
      setClasificacion_atc_3(dataAtc[1].clasificacion_atc_3);
      setClasificacion_atc_4(dataAtc[2].clasificacion_atc_4);
      setClasificacion_atc_5(dataAtc[3].clasificacion_atc_5);
    }
  };

  const onChangeAtc2 = async (value) => {
    const res = await fetchHelpToken("interna/cambia-atc2", { value }, "POST");
    const data = await res.json();

    if (data.info.msg) {
      form.setFieldsValue({ clasificacion_atc_3: "" });
      form.setFieldsValue({ clasificacion_atc_4: "" });
      form.setFieldsValue({ clasificacion_atc_5: "" });
      let dataAtc = JSON.parse(data.info.data);
      setClasificacion_atc_3(dataAtc[0].clasificacion_atc_3);
      setClasificacion_atc_4(dataAtc[1].clasificacion_atc_4);
      setClasificacion_atc_5(dataAtc[2].clasificacion_atc_5);
    }
  };

  const onChangeAtc3 = async (value) => {
    const res = await fetchHelpToken("interna/cambia-atc3", { value }, "POST");
    const data = await res.json();
    if (data.info.msg) {
      form.setFieldsValue({ clasificacion_atc_4: "" });
      form.setFieldsValue({ clasificacion_atc_5: "" });
      let dataAtc = JSON.parse(data.info.data);
      setClasificacion_atc_4(dataAtc[0].clasificacion_atc_4);
      setClasificacion_atc_5(dataAtc[1].clasificacion_atc_5);
    }
  };

  const onChangeAtc4 = async (value) => {
    const res = await fetchHelpToken("interna/cambia-atc4", { value }, "POST");
    const data = await res.json();
    if (data.info.msg) {
      form.setFieldsValue({ clasificacion_atc_5: "" });
      let dataAtc = JSON.parse(data.info.data);
      setClasificacion_atc_5(dataAtc[0].clasificacion_atc_5);
    }
  };

  function filterOption(inputValue, option) {
    return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  }

  const handleSumit = () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        values.cod_producto = cod_producto;
        values.cod_luc = cod_luc;
        let archivos = [];
        archivos.push(fileListPdf[0]);
        archivos.push(fileList[0]);
        archivos.push(fileList[1]);

        let llaves = await helperLlaves(archivos);

        if (llaves.length === 3) {
          delete values?.imagenes;
          delete values?.file_registro_sanitario;
          const res = await fetchHelpToken("interna/editar-producto-portal", { values, completo: 1, llaves }, "POST");
          const data = await res.json();
          if (data.info.msg) {
            if (fileLlaves.length > 0) {
              const requestSettings = {
                body: JSON.stringify(fileLlaves),
                method: "POST",
                headers: {
                  Accept: "application/json",
                },
              };
              await fetch(`${baseUrlAllku}proveedores/eliminar`, requestSettings);
            }
            if (productos) {
              if (productos.length > 1) {
                loadData(form1.getFieldsValue().lab);
              } else {
                await loadData(0);
                form1.setFieldsValue({ lab: 0 });
              }
            }
            notification.success({
              message: "Ok",
              description: "Se actualizó correctamente la información del producto.",
              placement: "bottomRight",
            });

            setModal(false);
            setLoading(false);
            return;
          } else {
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        setLoading(true);
        const tabMapping = {
          1: tab1,
          2: tab2,
          3: tab3,
          5: tab5,
          6: tab6,
          7: tab7,
          8: tab8,
          9: tab9,
        };
        error.errorFields?.some((el) => {
          const fieldName = el.name[0];

          for (const [key, tab] of Object.entries(tabMapping)) {
            if (tab.includes(fieldName)) {
              setTabKey(Number(key));
              return true;
            }
          }
          return false;
        });

        Swal.fire({
          title: "¿Estás seguro de actualizar?",
          text: "Si continúas, el producto se actualizará pero permanecerá en estado 'Pendiente' hasta que toda la información requerida se complete. Una vez que llenes todos los campos obligatorios, el producto desaparecerá del listado de pendientes.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sí, hacerlo!",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            let archivos = [];
            if (fileListPdf?.length > 0) {
              archivos.push(fileListPdf[0]);
            }

            if (fileList && fileList?.length > 0) {
              for (let i = 0; i < fileList.length; i++) {
                if (fileList[i]) {
                  archivos.push(fileList[i]);
                }
              }
            }

            let llaves = await helperLlaves(archivos);

            error.values.cod_producto = cod_producto;
            error.values.cod_luc = cod_luc;

            delete error.values?.imagenes;
            delete error.values?.file_registro_sanitario;
            let data;
            if (tipoCompletado === 0) {
              const res = await fetchHelpToken(
                "interna/editar-producto-portal",
                { values: error.values, completo: 0, llaves },
                "POST"
              );
              data = await res.json();
            } else if (tipoCompletado === 1) {
              const res = await fetchHelpToken(
                "interna/editar-producto-portal",
                { values: error.values, completo: 1, llaves },
                "POST"
              );
              data = await res.json();
            }

            if (data.info.msg) {
              if (fileLlaves.length > 0) {
                const requestSettings = {
                  body: JSON.stringify(fileLlaves),
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                  },
                };
                await fetch(`${baseUrlAllku}proveedores/eliminar`, requestSettings);
              }
              setModal(false);
              Swal.fire("Completado", "Tu acción ha sido completada", "success");
              return;
            }
          }
        });
        setLoading(false);
      });
  };

  const handleCreate = async () => {
    form
      .validateFields()
      .then(async (value) => {
        setLoading(true);
        value.costo_promedio = 0;
        value.costo_compra = 0;
        value.cod_proveedor2 = "";
        value.cod_lorFarm = "";
        value.estado = 1;
        value.servicio = 0;
        value.descuento = 0;
        delete value.imagenes;
        delete value.file_registro_sanitario;

        const res = await fetchHelpToken("interna/crear_producto", { value, cod_usuario: cod_luc }, "POST");
        const data = await res.json();

        if (data.msg) {
          Swal.fire("Ok", "Se creó el producto correctamente", "success");
          form.resetFields();
          setLoading(false);
          await fetchHelpToken("productos/notifica", { nombre: value.nombre_del_producto }, "POST");
          return;
        } else {
          Swal.fire("Advertencia", "Ocurrio un error al guardar!", "warning");
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        const tabMapping = {
          1: tab1,
          2: tab2,
          3: tab3,
          5: tab5,
          6: tab6,
          7: tab7,
          8: tab8,
          9: tab9,
        };
        error.errorFields?.some((el) => {
          const fieldName = el.name[0];

          for (const [key, tab] of Object.entries(tabMapping)) {
            if (tab.includes(fieldName)) {
              setTabKey(Number(key));
              return true;
            }
          }
          return false;
        });
        return Swal.fire("Error", "Algunos datos son obligatorios para registrar el producto!", "error");
      });
  };

  const onChangeTab = (e) => {
    setTabKey(e);
  };

  return {
    layout,
    dateFormat,
    laboratorios,
    principiosActivos,
    clasificacion_medicamento,
    clasificacion_atc_1,
    clasificacion_atc_2,
    clasificacion_atc_3,
    clasificacion_atc_4,
    clasificacion_atc_5,
    presentaciones,
    medidas,
    condiciones,
    tipoMercado,
    formaAplicacion,
    grupoObjetivo,
    tipoVentas,
    tipoExhibicion,
    tipoProducto,
    form,
    showCalificacion,
    onChangeAtc1,
    onChangeAtc2,
    onChangeAtc3,
    onChangeAtc4,
    filterOption,
    handleSumit,
    loading,
    setLoading,
    tab1,
    tabKey,
    onChangeTab,
    handleTipoProducto,
    spining,
    fileList,
    setFileList,
    handleChange,
    handlePreview,
    previewVisible,
    setPreviewVisible,
    previewImage,
    beforeUpload,
    previewPdf,
    previewVisiblePdf,
    setPreviewVisiblePdf,
    fileListPdf,
    handleChangePdf,
    categorias,
    handleCreate,
    loader,
    fkIdPerfil,
  };
};
