import React, { useState } from "react";
import { Form, Input, Button, Image, Card } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import logo from "./logo.svg";
import { Link } from "react-router-dom";
import { fetchHelp } from "./../../helper/fetch";
import Swal from "sweetalert2";
import { MdPerson, MdSend } from "react-icons/md";

const Recuperar = () => {
  const [loadings, setLoadings] = useState(false);
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    setLoadings(true);
    const res = await fetchHelp("auth/recovery", values, "POST");
    const data = await res.json();
    if (!data.ok) {
      setLoadings(false);
      form.resetFields();
      return Swal.fire("Ok", data.msg, "success");
    }
    Swal.fire("Ok", data.msg, "success");
    setLoadings(false);
    form.resetFields();
  };

  return (
    <div className='App'>
      <div className='container_card'>
        <div className='site-card-border-less-wrapper'>
          <Card
            bordered={false}
            style={{
              maxWidth: "500px",
              height: "400px",
            }}
          >
            <Form
              form={form}
              name='normal_login'
              className='login-form'
              layout='vertical'
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <div className='login__logo'>
                <Image style={{ maxWidth: "470px" }} src={logo} preview={false} />
              </div>

              <Form.Item
                label='Ingrese su usuario para reestablecer la contraseña'
                name='usuario'
                rules={[
                  {
                    required: true,
                    message: "Ingrese su usuario!",
                  },
                ]}
              >
                <Input
                  prefix={<MdPerson className='site-form-item-icon' size={24} />}
                  placeholder='Usuario'
                  autoComplete='off'
                  size='large'
                />
              </Form.Item>
              <Form.Item>
                <Button
                  size='large'
                  type='primary'
                  htmlType='submit'
                  className='login-form-button'
                  loading={loadings}
                  icon={<MdSend style={{ position: "absolute", right: "10px" }} size={24} />}
                >
                  Enviar
                </Button>
              </Form.Item>

              <Form.Item>
                <Link className='login-form-forgot' to='/auth/login' style={{ fontSize: "15px" }}>
                  <ArrowLeftOutlined /> Ir al inicio de sesión
                </Link>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Recuperar;
