import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  credential: {
    idUsuario: -1,
  },
  isLoading: true,
  temp: null,
};

export const authCredential = createSlice({
  name: "login",
  initialState,
  reducers: {
    auth: (state, action) => {
      state.credential = action.payload.user;
    },
    loader: (state, action) => {
      state.isLoading = action.payload;
    },
    logout: (state, action) => {
      state.credential = {};
      state.credential.idUsuario = -1;
      state.temp = null;
    },
    setTemporal: (state, action) => {
      state.temp = action.payload;
    },
  },
});

export const { auth, loader, logout, setTemporal } = authCredential.actions;
