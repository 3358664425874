import React, { useState } from "react";
import { Menu, Modal } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/slices/auth/login";
import { MdLogout, MdPassword, MdPerson } from "react-icons/md";
import NewPass from "./../auth/NewPass";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const rootSubmenuKeys = ["sub1", "sub2", "sub3"];

const OptionsApp = () => {
  const dispatch = useDispatch();
  const { credential, temp } = useSelector((state) => state.auth);
  const { persona } = credential;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("x-token");
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const items = [
    getItem(
      <Link to=''></Link>,
      "sub1",
      <MdPerson size={24} style={{ backgroundColor: "white", borderRadius: "50px" }} />,
      [
        getItem(
          <Link to='usuarios' style={{ display: "flex", alignItems: "center" }}>
            <span>
              <MdPerson size={22} color={"green"} />
            </span>
            <span style={{ marginBottom: "10px", marginLeft: "5px" }}>
              {persona.nombres?.toUpperCase()} {persona.apellidos?.toUpperCase()}
            </span>
          </Link>,
          "1"
        ),
        getItem(
          <p onClick={!temp && showModal} style={{ display: "flex", alignItems: "center" }}>
            <span>
              <MdPassword size={22} color={"blue"} />
            </span>
            <span style={{ marginBottom: "10px", marginLeft: "5px" }}>CAMBIAR CONTRASEÑA</span>
          </p>,
          "2"
        ),
        getItem(
          <p onClick={handleLogout} style={{ display: "flex", alignItems: "center" }}>
            <span>
              <MdLogout className='site-form-item-icon' size={22} color={"red"} />
            </span>
            <span style={{ marginBottom: "10px", marginLeft: "5px" }}>SALIR</span>
          </p>,
          "3"
        ),
      ]
    ),
  ];
  const [openKeys, setOpenKeys] = useState([]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <>
      {!temp && (
        <Menu
          mode='horizontal'
          openKeys={openKeys}
          //onClick={() => dispatch(userModal({ modal: false }))}
          onOpenChange={onOpenChange}
          triggerSubMenuAction='click'
          className='menu__2'
          style={{
            border: "none",
            boxShadow: "none",
            background: "#6a2831",
            color: "#6a2831",
          }}
          items={items}
          selectable={false}
        />
      )}
      <Modal
        title='Actualizar contraseña'
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        transitionName=''
      >
        <NewPass
          title='Escriba su nueva contraseña de acceso al sistema'
          handleCancel={handleCancel}
        />
      </Modal>
    </>
  );
};

export default OptionsApp;
