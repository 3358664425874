import { Form, Input, Modal, Spin, Table } from "antd";
import React from "react";
import { useViewProducts } from "../../hooks/hooksproductos/useViewProducts";
import FormProduct from "./FormProduct";
import Card from "antd/lib/card/Card";

const ViewProducts = () => {
  const { handleLoadProduct, productos, columns, modal, setModal, form, cod_producto, loader, estado, setLoader } =
    useViewProducts();
  return (
    <>
      <Card title='BUSCAR PRODUCTOS' style={{ marginBottom: "5px", width: "100%" }}>
        <Form form={form}>
          <Form.Item label='Escriba el nombre del producto' name='producto'>
            <Input.Search onSearch={handleLoadProduct} />
          </Form.Item>
        </Form>
      </Card>

      {loader && (
        <Spin tip='Espere, por favor!' size='large' style={{ display: "block", margin: "auto", textAlign: "center" }}></Spin>
      )}
      {productos.length > 0 && (
        <Table
          bordered
          pagination={false}
          dataSource={productos}
          columns={columns}
          size='small'
          scroll={{ x: 900, y: "55vh" }}
        />
      )}
      <Modal
        title='GESTIONAR PRODUCTO'
        visible={modal}
        onCancel={() => setModal(false)}
        footer={null}
        width={"1200px"}
        transitionName=''
        destroyOnClose='true'
      >
        <FormProduct
          cod_producto={cod_producto}
          setModal={setModal}
          form1={form}
          estado={estado}
          setLoader={setLoader}
          tipo={0}
          tipoCompletado={1}
        />
      </Modal>
    </>
  );
};

export default ViewProducts;
